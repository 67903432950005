import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import { Helmet } from "react-helmet";

import Banner from '../includes/Banner';

import useMetadata from '../../hooks/useMetadata';

import apiService from '../../utils/apiService';
 

const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function TermsConditions(){

    
  const [value, setValue] = useState('1');
  const [CareerData, setCareerData] = useState(null); 
  const [error, setError] = useState(null);  
  const [aboutBanner    , setaboutBanner ] = useState(null);

  
  const { metadata,  metaError } = useMetadata();
    


  const fetchData = async () => {

     
    try {
      const response = await apiService('terms_conditions');
      setCareerData(response.data.terms_and_conditions);
      setaboutBanner(response.data.banner );   
      console.log(CareerData);
    } catch (err) {
      setError(err.message);
    }
  };
 


  useEffect(() => {
     
    fetchData(); 


  }, [])




    return(
        <>

        
     
	{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}
	  

        <Headroom>
            <Header />
        </Headroom>
         

         

        {/* CareerData?.image_full_url ? (
        <section className="inner-banner">
          <img src={CareerData?.image_full_url} alt="" />
          <div className="container-fluid">
            <h1 dangerouslySetInnerHTML={{ __html: CareerData?.title }} />
          </div>
        </section>

      ) : null */}

{aboutBanner ? ( 
        <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner.name} />
        ) : null}



        <section className='contact-form-head'>
            <div className='form-head'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='content_static'>
                               <div dangerouslySetInnerHTML={{ __html: CareerData?.content }} />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>


        </>
    )
}

export default TermsConditions