import React, { useState, useEffect } from 'react';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import useMetadata from '../../hooks/useMetadata';



import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import BlogListCom from '../blog/BlogListCom';
import apiService from '../../utils/apiService';
 import Banner from '../includes/Banner';

import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


function ResearchAndDevelopment(){
  
  const [about_data, setabout_data] = useState(null); 
    const [aboutresearch_and_development      , setaboutresearch_and_development ] = useState(null);
    const [aboutBanner    , setaboutBanner ] = useState(null);
    const [aboutInformations , setaboutInformations] = useState(null);
  
  
    const [error, setError] = useState(null);

    const { metadata,  metaError } = useMetadata();

    
  const getabout = async () => {
    try {
      const response = await apiService('researchanddevelopment');
      setabout_data(response.data); 
      setaboutBanner(response.data.banner );  
      setaboutresearch_and_development(response.data.research_and_development ); 
      setaboutInformations(response.data.informations ); 

    } catch (err) {
      setError(err.message);
    }
   };


   
      useEffect(() => { 
         getabout();  
     
      }, [])


 
    return(
      
        <>
      
      {metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}

      
    <Headroom>
    <Header />
    </Headroom>


    {/* aboutBanner ? ( 
        <section className="inner-banner">  
            {aboutBanner.image && <img src={aboutBanner.image} alt={aboutBanner.name} />} 
            <div className="container-fluid">
                <h1>{aboutBanner.name}</h1>
            </div>
        </section>

) : null */}
        

        {aboutBanner ? ( 
          <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner.name}   youtube_video_id={aboutBanner.youtube_video_id} />
    ) : null}



        {aboutresearch_and_development ? (   
        <section className='rnd'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='head-text'>
                  <h2>{aboutresearch_and_development.title } </h2>
                  <div dangerouslySetInnerHTML={{ __html: aboutresearch_and_development.content }} />
                </div>
              </div>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='img'> 
                {aboutresearch_and_development.image_full_url && <img src={aboutresearch_and_development.image_full_url} alt={aboutresearch_and_development.title} />}
                </div>
              </div>
            </div>
            </div>
            </section>
) : null}

<section className='yotube_vide_rnd '>
          <div className='container-fluid'>

            {aboutresearch_and_development?.youtube_video_id ? (
            <div className='row'>
              <div className='col-md-12 col-sm-12 col-xs-12'>
                <div className='youtube_videos'>
                  
                <div className="youtube-video-container">
                   <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${aboutresearch_and_development.youtube_video_id}?autoplay=1&controls=0&rel=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    
                ></iframe><br/><br/>
                                </div>
                            
                                </div>
                              </div>
                              
                            </div>

                ) : null}

</div>
</section>

<section className='rnd '>
          <div className='container-fluid'>
 
 {aboutInformations?.map((solution, index) => (
  <div className='one_sout_as' key={index}>
    <div className='container-fluid' >
      <div className='row'>
        {/* Check if the index is even or odd */}
        {index % 2 === 0 ? (
          <>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='img borderround'>

                {solution.embed_video ? (
                  <div className='embeded_Coded' dangerouslySetInnerHTML={{ __html: solution.embed_video }}></div>
                ) : (
                  <img src={solution.image_full_url} alt={solution.title} />
                )}


              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='head-text'>
                <h2 dangerouslySetInnerHTML={{ __html: solution.title }} />
                <div dangerouslySetInnerHTML={{ __html: solution.content }}></div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='col-md-6 col-sm-6 col-xs-12 order-2 order-md-1'>
              <div className='head-text'>
                <h2 dangerouslySetInnerHTML={{ __html: solution.title }} />
                <div dangerouslySetInnerHTML={{ __html: solution.content }}></div>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12 order-1 order-md-2'>
            <div className='img borderround'>

                  {solution.embed_video ? (
                    <div className='embeded_Coded' dangerouslySetInnerHTML={{ __html: solution.embed_video }}></div>
                  ) : (
                    <img src={solution.image_full_url} alt={solution.title} />
                  )}


                  </div>
            </div>
          </>
        )}
      </div>
    </div>
  </div>
))}
 

  
               
              


{/* 
            <div className='row'>
              <div className='col-md-12'>
                <OwlCarousel className='owl-theme blog-slider reacher_slider' loop margin={30} nav>

                {aboutInformations.map((solution) => (
                          
                          <div class='item' key={solution.id}>
                            <div className='details'>
                              <div className='img'>   
                                  {solution.image_full_url && <img src={solution.image_full_url} alt={solution.title} />}
                              </div>
                              <div className='text'>
                                <h4 dangerouslySetInnerHTML={{ __html: solution.title }} />
                                <div dangerouslySetInnerHTML={{ __html: solution.content }}></div>
                                 
                              </div>
                            </div>
                          </div>

                          
                        ))}
 
                </OwlCarousel>
              </div>
            </div>
*/}

          </div>
        </section>

        

        
        
<BlogListCom />
        
        </>
    )
}

export default ResearchAndDevelopment