import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link} from 'react-router-dom'  

import apiService from '../../utils/apiService';
import { Search, X } from "lucide-react";
import { useNavigate } from "react-router-dom";


function NewMain() {
  const [error, setError] = useState(null);

  const [isOpen4, setIsOpen4] = useState(false);
  const [keyword, setKeyword] = useState("");

 

  const navigate = useNavigate();

    const location = useLocation();

    const [customersList    , setcustomersList] = useState(null);
    const [businessMenu    , setbusinessMenu] = useState(null);


    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const [isOpen1, setIsOpen1] = useState(false);
    const menuRef1 = useRef(null);


    const [isOpen3, setIsOpen3] = useState(false);
    const menuRef3 = useRef(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

     
    const toggleDropdown = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
      };
  
      const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          setIsOpen(false);
        }
      };

      const toggleDropdown1 = (e) => {
        e.preventDefault();
        setIsOpen1(!isOpen1);
      };
  
      const handleClickOutside1 = (e) => {
        if (menuRef1.current && !menuRef1.current.contains(e.target)) {
          setIsOpen1(false);
        }
      };
  

      const toggleDropdown3 = (e) => {
        e.preventDefault();
        if (isMobile) {
          setIsOpen3(!isOpen3);
        }
      };

      const close_menu = (e) => {
        setIsOpen3(false);
      };

  
      const handleClickOutside3 = (e) => {
        if (menuRef3.current && !menuRef3.current.contains(e.target)) {
          if (isMobile) {
            // setIsOpen3(false);
          }
        }
      };

      const handleSearch = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
          navigate(`/search?query=${keyword}`);
        }
      };


      useEffect(() => {
        // Detect screen size and update `isMobile`
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);





  
    


  const [error1, setError1] = useState(null);
    
  const getabout = async () => {
    try {
      const response = await apiService('customers');
      setcustomersList(response.data.customers);  
    } catch (err) {
        setError1(err.message);
    }
   };


   const getBusiness_menu = async () => {
    try {
      const response = await apiService('business_hover_menu_list');
      setbusinessMenu(response.data.business_menu);  
    } catch (err) {
        setError1(err.message);
    }
   };


   const getFirstWords = (html, wordCount) => {
    const text = new DOMParser().parseFromString(html, "text/html").body.textContent || "";
    return text.split(/\s+/).slice(0, wordCount).join(" ");
  };



   
      useEffect(() => { 
       // getabout();  
        getBusiness_menu();  
     
     
      }, [])

 
 // Close dropdown when clicking outside
 useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  // Close dropdown when route changes
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);


   // Close dropdown when clicking outside
 useEffect(() => {
    document.addEventListener("click", handleClickOutside1);
    return () => document.removeEventListener("click", handleClickOutside1);
  }, []);

  // Close dropdown when route changes
  useEffect(() => {
    setIsOpen1(false);
  }, [location.pathname]);


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside3);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside3);
    };
  }, []);

  // Close dropdown when route changes
  /* useEffect(() => {
    setIsOpen3(false);
  }, [location.pathname]);
*/




    const isHomePage = location.pathname === '/';

    console.log(isOpen3);
    return (
        <>
            <header  className={isHomePage ? '' : 'inner_pages'}>
                <div className='header-in'>
                    <div className="container-fluid clearfix">
                        <div className="logo">
                            <Link to="/" >  
                                <img className='black_logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
                                <img className='white_logo' src={`${process.env.PUBLIC_URL}/logo_white.png`} />
                            </Link>  
                        </div>

                        <div className='search mobilesearch'> 
                            {!isOpen4 ? (
                                <Search
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={() => setIsOpen4(true)}
                                />
                              ) : (
                                <div className="flex items-center border p-2 rounded-lg shadow-md">
                                  <form onSubmit={handleSearch} className="flex items-center w-full">
                                    <input
                                      type="text"
                                      placeholder="Search..."
                                      className="border-none focus:outline-none px-2 w-full"
                                      value={keyword}
                                      onChange={(e) => setKeyword(e.target.value)}
                                    />
                                    <button type="submit" className="ml-2 p-1 bg-blue-500 text-white rounded">Go</button>
                                  </form>
                                  <X
                                    className="w-6 h-6 cursor-pointer text-gray-500 ml-2"
                                    onClick={() => {
                                      setIsOpen4(false);
                                      setKeyword("");
                                      
                                    }}
                                  />
                                </div>
                              )}
                                                    </div>


                        <a href="javascript:void(0)" className="toggle_botton_menu" onClick={toggleDropdown3}> <span></span><span></span><span></span>  </a> 
                        {(isOpen3 || !isMobile) && (
                        <nav className="main-nav" ref={menuRef3}>

                              <button className="close_menu" onClick={close_menu}><i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                        
                            <ul  >

                            <li className='sub_menu new_sub_menu'  ref={menuRef}>
                                    <a href='javascript:void(0)' onClick={toggleDropdown}>  Business  <span className='plus_icon'></span>  </a>
                                    
                                    {isOpen && <div className='new_sub_list_drop'>
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                     <div className='menulist_side'>
                                                        <ul> 

                                                        {businessMenu && businessMenu.map((item, index) => ( 
                                                                <li key={index}> <Link to={`/business/${item.slug}`} >  {item.title}  </Link>
                                                            
                                                            <div className='menulist_side_big'>
                                                                <div className='row'>
                                                                {item.efficiency_solutions && item.efficiency_solutions.map((sub_item, index_2) => (
                                                                    
                                                                    <div className='col-md-4'>
                                                                        <div className='one_item_menu'>
                                                                            <div className='meni_image'>
                                                                                    <img src={sub_item.image_full_url}/>
                                                                            </div>
                                                                            <h3> {sub_item.title}  </h3>
                                                                             {/* <div dangerouslySetInnerHTML={{ __html: sub_item.content.slice(0, 100)  }} />   */}
                                                                            <div><p>{new DOMParser().parseFromString(sub_item.content, "text/html").body.textContent.slice(0, 100)}...</p></div>  

                                                                            {/*  <div><p>{getFirstWords(sub_item.content, 15)}</p></div>   */}

                                                                             
                                                                        </div>
                                                                </div>
                                                                
                                                                
                                                                ))}
                                                              
                                                        </div>
                                                    </div>


                                                            
                                                            
                                                            </li>


                                                        ))}
 






                                                        </ul>
                                                    </div>   

                                                </div>

                                               

                                            </div>
                                        </div>       

                                    </div> }
 
                                
                                </li>
 
                                <li className='sub_menu'  ref={menuRef1}> 
                                <a href='javascript:void(0)' onClick={toggleDropdown1}>   About Us  <span className='plus_icon'></span>  </a> 
                                {isOpen1 &&  <ul className='sub_menu_list'>
                                        <li> <Link to="/about" >  About Us  </Link></li>
                                        <li> <Link to="/research_and_development" >  Research & Development  </Link></li>
                                        <li> <Link to="/certifications_and_affiliations" >  Certifications & Affiliations  </Link></li>
                                        <li> <Link to="/projects" >  Projects </Link></li> 
                                        
                                    </ul> }
                                </li>
                                <li className='sub_menu'>  
                                    <Link to="/our_customer" >  Our Customer  </Link>  
                                    {customersList ? (
                                    <ul className='sub_menu_list'>
                                            {customersList.map((customer) => (
                                                <li> <Link to={`/our_customer/${customer.slug}`} >  {customer.title}  </Link></li>
                                                
                                            ))} 
                                        
                                    </ul>
                                                                 
) : null}


                                </li> 
                                <li>  <Link to="/career" >  Careers  </Link> </li>
                                {/* <li>  <Link to="/contact" >  Contact  </Link>  </li>   */}
                            </ul>
                            <div className='talk-btn'>
                            <Link to="/contact" > Let’s Talk </Link>
                            </div>
                            <div className='meta_connect_logo'>
                                <a href='https://app.buildingconnected.com/public/54c141a34f015f0900653cb0'  target='_blank' title="Metco Project Connect">
                                    <img className='black_logo_meta' src={`${process.env.PUBLIC_URL}/meta_connect_logo.png`} />
                                    <img className='white_logo_meta' src={`${process.env.PUBLIC_URL}/meta_connect_logo_white.png`} />
                                </a>

                            </div>
                             

                            <div className='search'> 
                            {!isOpen4 ? (
                                <Search
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={() => setIsOpen4(true)}
                                />
                              ) : (
                                <div className="flex items-center border p-2 rounded-lg shadow-md">
                                  <form onSubmit={handleSearch} className="flex items-center w-full">
                                    <input
                                      type="text"
                                      placeholder="Search..."
                                      className="border-none focus:outline-none px-2 w-full"
                                      value={keyword}
                                      onChange={(e) => setKeyword(e.target.value)}
                                    />
                                    <button type="submit" className="ml-2 p-1 bg-blue-500 text-white rounded">Go</button>
                                  </form>
                                  <X
                                    className="w-6 h-6 cursor-pointer text-gray-500 ml-2"
                                    onClick={() => {
                                      setIsOpen4(false);
                                      setKeyword("");
                                      
                                    }}
                                  />
                                </div>
                              )}
                                                    </div>


                           
                        </nav> 
                       )}
                    </div>
                </div>
            </header>
 
        </>
    )
}

export default NewMain;