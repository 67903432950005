import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import './assets/css/bootstrap.min.css'
import './assets/css/custom_style.css'
import './assets/css/custom_style_r.css'
import './assets/css/responive.css'
function App() {

  //const basename = process.env.NODE_ENV === 'production' ? '/metco-ngineering-react/build' : '/';

  const basename = process.env.NODE_ENV === 'production' ? '/' : '/';


  return (
    
    <>
      <Router basename={basename}>
        <Routing />
      </Router>
    </>
  );
}

export default App;
