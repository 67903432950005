import React, { useState, useEffect } from 'react';
import Header from '../Header/newMain';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import Headroom from 'react-headroom';
import apiService from '../../utils/apiService';

import { formatDate } from '../../utils/dateFormat';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Banner from '../includes/Banner';

import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function Business() {

  const [value, setValue] = useState('1');
  const [businessData, setbusinessData] = useState(null);
  const [bannerList, setbannerList] = useState(null);
  const [businesses_solution, setbusinesses_solution] = useState(null);
  const [efficiency_solutions, setefficiency_solutions] = useState(null);
  const [solutionsTabs, setsolutionsTabs] = useState(null);
  const [metcoData, setmetcoData] = useState(null);
  const [error, setError] = useState(null);

  const [businessTabs, setbusinessTabs] = useState(null);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const fetchData = async () => {

     
    try {
      const response = await apiService('business');
      setbusinessData(response.data);
      setbannerList(response.data.banners);
      setbusinesses_solution(response.data.section1);
      //setefficiency_solutions(response.data.businesses[0].efficiency_solutions);
      setsolutionsTabs(response.data.section2);
      setmetcoData(response.data.section3);
      //  sethasVideo = sliderlist.some(item => item.video);
    } catch (err) {
      setError(err.message);
    }
  };


  const get_business_tabs = async () => {
 

    try {
      const response = await apiService('business_tabs');
      setbusinessTabs(response.data.business_tabs);

    } catch (err) {
      setError(err.message);
    }
  };


  useEffect(() => {
    
    console.log('Fetching data for category:');
    AOS.init();
    fetchData();
    get_business_tabs();


  }, [])




  return (
    <>

    {bannerList ? (
      <DynamicMeta
        title={bannerList.name}
        description="This is a dynamic description for Business  page."
        keywords="react, dynamic, meta tags, SEO"
      />
    ) : null}

      <Headroom>
        <Header />
      </Headroom>

      {bannerList ? (
        <section className="inner-banner">
          <img src={bannerList.image} alt="" />
          <div className="container-fluid">
            <h1 dangerouslySetInnerHTML={{ __html: bannerList.name }} />
          </div>
        </section>

      ) : null}

    {bannerList ? ( 
    <Banner image={bannerList.image} video={bannerList.video} name={bannerList.name} />
    ) : null}

      {businesses_solution ? (



        <section className="efficiency">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="text">
                  <h2 dangerouslySetInnerHTML={{ __html: businesses_solution.title }} />
                  <div dangerouslySetInnerHTML={{ __html: businesses_solution.content }} />

                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="img">

                  <img src={businesses_solution.image} alt={businesses_solution.title} />
                </div>
              </div>
            </div>
          </div>
        </section>

      ) : null}


      {solutionsTabs.length}
      {solutionsTabs && solutionsTabs.length > 0 ? (
        <section className="sustaince_efficiency_tabs">
          <div className="container-fluid">
            <div className="box_left_menu ">
              <div className="row">
                <TabContext value={value}>
                  <div className=' col-md-6 col-sm-6 col-xs-12' >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {solutionsTabs.map((solution) => (
                          <Tab key={solution.id} label={solution.title} value={solution.id.toString()} />
                        ))}
                      </TabList>
                    </Box>

                  </div>
                  <div className=' col-md-6 col-sm-6 col-xs-12' >
                    {solutionsTabs.map((solution) => (
                      <TabPanel key={solution.id} value={solution.id.toString()}>
                        <div className="table_design_panel">
                          <h3>{solution.title}</h3>
                          <div dangerouslySetInnerHTML={{ __html: solution.content }}></div>
                          <a href="" className="btn btn-primary">View More</a>
                        </div>
                      </TabPanel>
                    ))}


                  </div>
                </TabContext>
              </div>

            </div>
          </div>
        </section>

      ) : null}



      {metcoData ? (

        <section className="energy-star">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-sm-7 col-xs-12">
                <div className="img">
                  <img src={metcoData.image} alt={metcoData.title} />
                </div>
              </div>
              <div className="col-md-4 col-sm-5 col-xs-12">
                <div className="text">
                  <div className="hand">
                    <img src={metcoData.icon_image} alt={metcoData.title} />
                  </div>
                  <h2 dangerouslySetInnerHTML={{ __html: metcoData.title }}></h2>
                  <div dangerouslySetInnerHTML={{ __html: metcoData.content }}></div>
                </div>
              </div>
            </div>
          </div>
        </section>


      ) : null}


{ businessTabs ? (

      <section className="energy_efficientcy_tabs">

        <TabContext value={value}>

          <div className="container-fluid">
            <div className="box_left_menu_s ">
              <div className=' col-md-12 col-sm-12 col-xs-12' >
                <h2 className='section_title'>Energy Efficiency Services</h2>
              </div>

              <div className="row">


                <div className=' col-md-12 col-sm-12 col-xs-12' >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                       {businessTabs.map((businessTab) => (
                          <Tab key={businessTab.id} label={businessTab.name} value={businessTab.id.toString()} />
                        ))}
                    </TabList>
                  </Box>

                </div>

              </div>

            </div>

          </div>


          {businessTabs.map((businessTab1) => (
          <TabPanel key={businessTab1.id} value={businessTab1.id.toString()} >
            <div className="container-fluid">
              <div className="box_left_menu_s "> 
                <div className="row"> 

                  <div className=' col-md-12 col-sm-12 col-xs-12' >

                    <div className='table_design_panel02'>
                    
                    <div  dangerouslySetInnerHTML={{ __html: businessTab1?.section1?.content }}   ></div>
   
                    </div>

                  </div>

                </div>

              </div>

            </div>

            
              {businessTab1?.section2?.length > 0 ? (

                    
                          <section className="energy_efficientcy_tabs engery_setting_tabs">
                              <div className="container-fluid">
                                <div className="box_left_menu_s ">
                                  <div className=' col-md-12 col-sm-12 col-xs-12' >
                                    <h2 className='section_title'>Energy settings available are</h2>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12">
                                      <div class="featured-content-banner">

                                        <ul class="featured-panels">
                                        {businessTab1.section2.map((section2s) => (
                                          <li class="featured-panel active"   >
                                            <div class="panel-img">
                                              <img class="image_one" src={section2s.image_full_url} />
                                              {/**<img class="image_two" src="new_img/Jackson Hewitt_Website Defaultlogo.png" />  */}
                                              <div class="panel-text01">
                                                <h3 class=" h3 verticle_text">{section2s.title}</h3>

                                              </div>
                                              <div class="panel-text">

                                                <h3 class=" h3">{section2s.title}</h3>
                                                <p>{section2s.content}</p>
                                              </div>
                                            </div>
                                          </li>
                                          ))}
                                           
                                        </ul>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                            </section>

) : null }


{ businessTab1.section3 ? (
  <div  dangerouslySetInnerHTML={{ __html: businessTab1?.section3?.content }}   ></div>
) : null }
              
 



          </TabPanel>

))}
 

        </TabContext>
      </section>






) : null }



<section className="choosing-metco">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/choose.png`} />
                </div>
              </div>
            </div>
          </div>
        </section>
 
     

    </>
  )
}

export default Business