import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import useMetadata from '../../hooks/useMetadata';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import BlogListCom from '../blog/BlogListCom';

import ProjectListCom from '../includes/projects/ProjectListCom';
 
import apiService from '../../utils/apiService';
import Banner from '../includes/Banner';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

const usePageSlug = () => {
  const location = useLocation();
  const segments = location.pathname.split("/").filter(Boolean); // Removes empty strings
  return segments.length ? segments[segments.length - 1] : ""; // Returns the last segment (slug)
};


function Projects(){
  
 const [aboutBanner    , setaboutBanner ] = useState(null);
  const [homeProject, sethomeProject] = useState(null);
  const [error, setError] = useState(null);
  
  
    const fetchData = async () => {
      try {
        const response = await apiService('projects');
          sethomeProject(response.data.projects); 
          setaboutBanner(response.data.banners);  
      } catch (err) {
        setError(err.message);
      }
    };

    
  const slug = usePageSlug(); 
  console.log(slug)
  const meta_slug =  'metadata/' + slug; 


    const get_meta_details = async () => {
      try {
        const response = await apiService(meta_slug);
         // sethomeProject(response.data.projects); 
          // setaboutBanner(response.data.banners);  
      } catch (err) {
        setError(err.message);
      }
    };
  
    /*
    const aboutBanner = {
      image: `${process.env.PUBLIC_URL}/about-banner.png`,
      video: "",
      name: "Have A Look At Our Solar Success Stories",
      youtube_video_id: "",
    };
 */
    
    const { metadata,  metaError } = useMetadata();

    
      useEffect(() => { 
         AOS.init(); 
        fetchData(); 
        get_meta_details(); 
        
    
      }, [])

      

    return(
      
        <>
       {metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}
	  


    <Headroom>
    <Header />
    </Headroom>

 

        {aboutBanner ? ( 
          <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner.name}   youtube_video_id={aboutBanner.youtube_video_id} />
    ) : null}

{homeProject  ? ( 

        <section className='metco-engenering projeclisting' data-aos="fade-up" data-aos-duration="600">
              <div className='container-fluid'>
                <div className='row'>
                {homeProject.data.map((data01) => (
                  <div className='col-md-4'>
                     
                    <div className='one_project_inner'>
                      <div className='one_project_inner_img'>
                           <Link to={data01.slug}>  
                              {data01.image_full_url && <img src={data01.image_full_url} />}  
                          </Link>
                        </div> 
                        <div className='one_project_inner_content'>
                            <h3 dangerouslySetInnerHTML={{ __html: data01.title }} />
                            <h6>{data01.year}</h6>
                        </div> 
                       
                    </div> 
                  </div>
                   ))} 
                </div>
              </div>
            </section>

        
  ) :  null}

         
        
        </>
    )
}

export default Projects