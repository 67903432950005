import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import useMetadata from '../../hooks/useMetadata';

import ProjectListCom from '../includes/projects/ProjectListCom';
import apiService from '../../utils/apiService';

import Banner from '../includes/Banner';


import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function CustomerDetails(){

    
    const { slug } = useParams(); 
    const [aboutBanner    , setaboutBanner ] = useState(null);
    const [customersData    , setcustomersData ] = useState(null);
    const [customersDatacustomer    , setcustomersDatacustomer ] = useState(null);
    const [customerscommitments   , setcustomerscommitments] = useState(null);
    const [customersScopes   , setcustomersScopes] = useState(null);
    const [error, setError] = useState(null);

    
    const page_api_path =  'customers/' + slug; 
  const getabout = async () => {
    try {
      const response = await apiService(page_api_path);
      setcustomersData(response.data);  
      setaboutBanner(response.data.banner );  
      setcustomersDatacustomer(response.data.customers.customer );  
      setcustomerscommitments(response.data.customers.pages );  
      setcustomersScopes(response.data.customers.scopes[0] );  

    } catch (err) {
      setError(err.message);
    }
   };

   
  const { metadata,  metaError } = useMetadata();
    


   
      useEffect(() => { 
        getabout();  
     
      }, [slug])



    return(
        <>

	{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}

        <Headroom>
            <Header />
        </Headroom>
        {/* aboutBanner ? ( 
            <section className="inner-banner">  
                {aboutBanner.image && <img src={aboutBanner.image} alt={aboutBanner.name} />} 
                <div className="container-fluid"> 
                    {customersDatacustomer.name && <h1>{customersDatacustomer.name}  </h1>}
                </div>
            </section>

    ) : null */}

    {aboutBanner ? ( 
    <Banner image={aboutBanner.image} video={aboutBanner.video} name={customersDatacustomer.name} />
    ) : null}
 
        <section className='c-and-u'>

        {customersDatacustomer ? ( 
            <div className='top-block'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                            <div className='text'>
                                <h2>{customersDatacustomer.name}</h2>
                                <label>Empowering education with endless possibilities</label>
                                <p dangerouslySetInnerHTML={{ __html: customersDatacustomer.content }} />
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                            <div className='img'>  
                                {customersDatacustomer.image && <img src={customersDatacustomer.image} alt={customersDatacustomer.name} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
) : null}

{customersScopes ? ( 
            <div className='bottom-block'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                            <div className='img'> 
                            {customersScopes.image && <img src={customersScopes.image} alt={customersScopes.name} />}
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                            <div className='text'>
                                <h2>{customersScopes.title}</h2>
                                <p className='scroll' dangerouslySetInnerHTML={{ __html: customersScopes.description }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        
) : null}


        </section>

    

        {customerscommitments ? ( 

        <section className='commitments'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='head'>
                            <h2>METCO’s Commitments</h2>
                            <p>METCO offers a number of benefits to colleges and universities<br />as we strive for improving education for a better future. </p>
                        </div>
                    </div>
                </div>
                <div className='row'>

                {customerscommitments.map((commitment) => (
                           
                            <div className='col-md-4 col-sm-4 col-xs-12'  key={commitment.id}>
                            <div className='commitments_item'>
                                <div className='img'> 
                                {commitment.image_full_url && <img src={commitment.image_full_url} alt={commitment.title} />}
                                </div>
                                <div className='text'>
                                    <h3 dangerouslySetInnerHTML={{ __html: commitment.title }} />
                                    <p className='descipt01'  dangerouslySetInnerHTML={{ __html: commitment.short_description }} /> 
                                    <div className='exp-more'>
                                        <a href=''>Explore More</a>
                                    </div>
                                </div>
                            </div>
                            </div>



                          
                        ))}

   
                </div>
            </div>
        </section>

                       
) : null}
          
<ProjectListCom />
        </>
    )
}

export default CustomerDetails