import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import useMetadata from '../../hooks/useMetadata';


import apiService from '../../utils/apiService';
 import Banner from '../includes/Banner';
 

const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function Career(){

   
  const [about_data, setabout_data] = useState(null); 
  const [aboutresearch_and_development      , setaboutresearch_and_development ] = useState(null);
  const [aboutBanner    , setaboutBanner ] = useState(null);
  const [aboutInformations , setaboutInformations] = useState(null);


  const [error, setError] = useState(null);

  const navigate = useNavigate();
   const handleJobClick = (solution) => {
    navigate("/career_details", { state: { job: solution } });
  };


  

  const { metadata,  metaError } = useMetadata();

const getabout = async () => {
  try {
    const response = await apiService('careers');
    setabout_data(response.data.careers); 
    setaboutBanner(response.data.banner );    

  } catch (err) {
    setError(err.message);
  }
 };


 
    useEffect(() => { 
       getabout();  
   
    }, [])



    return(
        <>

{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}
	  

        <Headroom>
            <Header />
        </Headroom>
         
        {/*
        <section className="inner-banner"> 
            <img src={`${process.env.PUBLIC_URL}/career-banner.png`} />
            <div className="container-fluid">
                <h1>Career</h1>
            </div>
        </section> */}

        {aboutBanner ? ( 
          <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner.name}   youtube_video_id={aboutBanner.youtube_video_id} />
    ) : null}

        {about_data ? (   

        <section className='contact-form-head'>
            <div className='form-head'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='head'>
                                <h2>CURRENT OPENINGS</h2>
                            </div>
                        </div>
                        <div className='career-accordian'>
                            <Accordion>
                            {about_data.map((solution) => (
                                <Accordion.Item eventKey={solution.id}>
                                    <Accordion.Header>
                                        <div className='head-block'>
                                            <h3>{solution.title}</h3>
                                            <p>{solution.sub_heading}</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='conact-form'>
                                            <div className='container-fluid'>
                                                <div className='career_descprition' dangerouslySetInnerHTML={{ __html: solution.description}} />  
                                                 <br/> 
                                                <div className='text-center'>
                                                    <a className='areer_lin_btn ' onClick={() => handleJobClick(solution)}>Apply Job </a>
                                                </div>
                                                {/*
                                                <form>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Your Name</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Email Address</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Phone</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Upload Resume</label>
                                                                <input type='file' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='submit-query'>
                                                                <input type='submit' value="Apply" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>  */}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                         ))}

                         {/*
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <div className='head-block'>
                                            <h3>Customer Service & Support</h3>
                                            <p>Minimum 5-7 years of job related experience Metcoengineering, Dallas, Texas</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    <div className='conact-form'>
                                            <div className='container-fluid'>
                                                <form>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Your Name</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Email Address</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Phone</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Upload Resume</label>
                                                                <input type='file' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='submit-query'>
                                                                <input type='submit' value="Apply" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <div className='head-block'>
                                            <h3>Cyber Security</h3>
                                            <p>Minimum 5-8 years of experience in domain Metcoengineering, Dallas, Texas</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    <div className='conact-form'>
                                            <div className='container-fluid'>
                                                <form>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Your Name</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Email Address</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Phone</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Upload Resume</label>
                                                                <input type='file' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='submit-query'>
                                                                <input type='submit' value="Apply" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <div className='head-block'>
                                            <h3>Engineering</h3>
                                            <p>Minimum 4-8 years of job related experience Metcogineering, Dallas, Texas</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    <div className='conact-form'>
                                            <div className='container-fluid'>
                                                <form>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Your Name</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Email Address</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Phone</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Upload Resume</label>
                                                                <input type='file' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='submit-query'>
                                                                <input type='submit' value="Apply" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <div className='head-block'>
                                            <h3>Science & Environmental</h3>
                                            <p>Minimum 5-7 years of job related experience Metcoengineering, Dallas, Texas</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    <div className='conact-form'>
                                            <div className='container-fluid'>
                                                <form>
                                                    <div className='row'>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Your Name</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Email Address</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Phone</label>
                                                                <input type='text' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Upload Resume</label>
                                                                <input type='file' />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='submit-query'>
                                                                <input type='submit' value="Apply" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                            </Accordion>
                           {/* <div className='view-all'>
                                <a href=''>VIEW  ALL</a>
                            </div> */ }
                        </div>
                    </div>
                </div>
            </div>
        </section>

) : null}


        </>
    )
}

export default Career