import React, { useState, useEffect } from 'react';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import BlogListCom from '../blog/BlogListCom';

import ProjectListCom from '../includes/projects/ProjectListCom';


import { formatDate } from '../../utils/dateFormat';


import useMetadata from '../../hooks/useMetadata';



import apiService from '../../utils/apiService';

import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


function Blogs(){
  

  const [homeProject, sethomeProject] = useState(null);
  const [error, setError] = useState(null);
  const [blogList, setblogList] = useState(null);
     
  
    const fetchData = async () => {
      try {
        const response = await apiService('projects');
          sethomeProject(response.data.projects); 
      } catch (err) {
        setError(err.message);
      }
    };
    

    
  const home_blogs = async () => {
    try {
      const response = await apiService('blogs');
      setblogList(response.data); 
    } catch (err) {
      setError(err.message);
    }
  };
    
    
  const { metadata,  metaError } = useMetadata();
    
      useEffect(() => { 
        fetchData();
        home_blogs();
    
    
      }, [])

      

    return(
      
        <>
       
	{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}

    <Headroom>
    <Header />
    </Headroom>


        <section className="inner-banner"> 
            <img src={`${process.env.PUBLIC_URL}/about-banner.png`} />
            <div className="container-fluid">
                <h1>Blogs</h1>
            </div>
        </section>
        
        {blogList && blogList.length > 0 ? ( 


        <section className='metco-engenering projeclisting' >
              <div className='container-fluid'>
                <div className='row'>
                {blogList.map((blog) => (
                  <div className='col-md-4'>
                     
                    <div className='one_project_inner'>
                      <div className='one_project_inner_img'>
                             {blog.image ? (
                                   <img src={blog.image} alt={blog.title} />
                                 ) : (
                                   <img src={`${process.env.PUBLIC_URL}/assets/img/default-image.jpg`}  alt="Default" />
                                 )}  
                        </div> 
                        <div className='one_project_inner_content'>
                           <br/>
                            <h3 dangerouslySetInnerHTML={{ __html: blog.title }} />
                            <br/>
                            <h6>{formatDate(blog.created_at)}</h6>
                            <div class="more">
                              <a href="/the-rise-of-renewable-energy-growth-of-renewable-energy-capacity-worldwide-between-2000-and-2023" data-discover="true"> Read More <img src="/metco-ngineering-react/build/blog-arrow.png" /> </a>
                          </div>

                        </div> 
                       
                    </div> 
                  </div>
                   ))} 
                </div>
              </div>
            </section>

        
  ) :  null}

         
        
        </>
    )
}

export default Blogs