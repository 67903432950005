import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom' ;
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
 
import apiService from '../../../utils/apiService';

function ProjectListCom() {

   const [homeProject, sethomeProject] = useState(null);
const [error, setError] = useState(null);
   

  const fetchData = async () => {
    try {
      //const response = await apiService('projects');
      const response = await apiService('home_projects');
        sethomeProject(response.data.projects); 
    } catch (err) {
      setError(err.message);
    }
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };


  
  
    useEffect(() => { 
      fetchData(); 
  
  
    }, [])



  return (
    <>
                
{homeProject  ? ( 
            <section className='metco-engenering'  >
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='head'>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="head2"> 
                               <h2 dangerouslySetInnerHTML={{ __html: homeProject.title }} />
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="text">
                              <div  dangerouslySetInnerHTML={{ __html: homeProject.content }} />   
                                 
                               

                            </div>
                        </div>
                    </div>



                     
                      
                       
                    </div>
                    <div className='two-col new_project_list01'>

                    {homeProject.map((data01) => (
                      <div className='small me_detail'>
                        <div className='img'> 
                          {data01.image_full_url && <img src={data01.image_full_url} />}  
                        </div>
                        <div className='text'>
                          <h4  dangerouslySetInnerHTML={{ __html: data01.title }} />
                         {/*  <div dangerouslySetInnerHTML={{ __html: data01.content }} /> */}
                          <div> <p>
                                  {stripHtml(data01.content).slice(0, 70) + 
                                    (stripHtml(data01.content).length > 70 ? "..." : "")}
                               </p> </div> 
                        </div>
                      </div>

                      ))} 

                       
                    </div> 
                    <div className='more'> 
                      <Link to="/projects" >  Explore More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

      ) :  null}




    </>
  )
}

export default ProjectListCom