import React, { useState, useEffect } from 'react';
import Header from '../Header/newMain';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import Headroom from 'react-headroom';
import apiService from '../../utils/apiService';

import { formatDate } from '../../utils/dateFormat';
import AOS from 'aos';
import 'aos/dist/aos.css';


import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function BusinessTest() {

  const [value, setValue] = useState('1');
  const [businessData, setbusinessData] = useState(null);
  const [bannerList, setbannerList] = useState(null);
  const [businesses_solution, setbusinesses_solution] = useState(null);
  const [efficiency_solutions, setefficiency_solutions] = useState(null);
  const [solutionsTabs, setsolutionsTabs] = useState(null);
  const [metcoData, setmetcoData] = useState(null);
  const [error, setError] = useState(null);

  const [businessTabs, setbusinessTabs] = useState(null);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const fetchData = async () => {
    try {
      const response = await apiService('business');
      setbusinessData(response.data);
      setbannerList(response.data.banners);
      setbusinesses_solution(response.data.section1);
      //setefficiency_solutions(response.data.businesses[0].efficiency_solutions);
      setsolutionsTabs(response.data.section2);
      setmetcoData(response.data.section3);
      //  sethasVideo = sliderlist.some(item => item.video);
    } catch (err) {
      setError(err.message);
    }
  };


  const get_business_tabs = async () => {
    try {
      const response = await apiService('business_tabs');
      setbusinessTabs(response.data.business_tabs);

    } catch (err) {
      setError(err.message);
    }
  };


  useEffect(() => {
    AOS.init();
    fetchData();
    get_business_tabs();


  }, [])




  return (
    <>

      <DynamicMeta
        title="Business Page | Metco Engineering "
        description="This is a dynamic description for Business  page."
        keywords="react, dynamic, meta tags, SEO"
      />


      <Headroom>
        <Header />
      </Headroom>

      {bannerList ? (
        <section className="inner-banner">
          <img src={bannerList.image} alt="" />
          <div className="container-fluid">
            <h1 dangerouslySetInnerHTML={{ __html: bannerList.name }} />
          </div>
        </section>

      ) : null}

      {businesses_solution ? (



        <section className="efficiency">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="text">
                  <h2 dangerouslySetInnerHTML={{ __html: businesses_solution.title }} />
                  <div dangerouslySetInnerHTML={{ __html: businesses_solution.content }} />

                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="img">

                  <img src={businesses_solution.image} alt={businesses_solution.title} />
                </div>
              </div>
            </div>
          </div>
        </section>

      ) : null}



      {solutionsTabs ? (
        <section className="sustaince_efficiency_tabs">
          <div className="container-fluid">
            <div className="box_left_menu ">
              <div className="row">
                <TabContext value={value}>
                  <div className=' col-md-6 col-sm-6 col-xs-12' >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {solutionsTabs.map((solution) => (
                          <Tab key={solution.id} label={solution.title} value={solution.id.toString()} />
                        ))}
                      </TabList>
                    </Box>

                  </div>
                  <div className=' col-md-6 col-sm-6 col-xs-12' >
                    {solutionsTabs.map((solution) => (
                      <TabPanel key={solution.id} value={solution.id.toString()}>
                        <div className="table_design_panel">
                          <h3>{solution.title}</h3>
                          <div dangerouslySetInnerHTML={{ __html: solution.content }}></div>
                          <a href="" className="btn btn-primary">View More</a>
                        </div>
                      </TabPanel>
                    ))}


                  </div>
                </TabContext>
              </div>

            </div>
          </div>
        </section>

      ) : null}



      {metcoData ? (

        <section className="energy-star">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-sm-7 col-xs-12">
                <div className="img">
                  <img src={metcoData.image} alt={metcoData.title} />
                </div>
              </div>
              <div className="col-md-4 col-sm-5 col-xs-12">
                <div className="text">
                  <div className="hand">
                    <img src={metcoData.icon_image} alt={metcoData.title} />
                  </div>
                  <h2 dangerouslySetInnerHTML={{ __html: metcoData.title }}></h2>
                  <div dangerouslySetInnerHTML={{ __html: metcoData.content }}></div>
                </div>
              </div>
            </div>
          </div>
        </section>


      ) : null}

 

<section className="energy_efficientcy_tabs">
            <div className="container-fluid">
                <div className="box_left_menu_s ">
                <div className=' col-md-12 col-sm-12 col-xs-12' >
                   <h2 className='section_title'>Energy Efficiency Services</h2>
                </div>
                
                    <div className="row">
                    <TabContext value={value}>
                        <div className=' col-md-12 col-sm-12 col-xs-12' >
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab label="Lighting & Control Upgrades" value="1" />
                              <Tab label="Conversion to LED Lighting" value="2" />
                              <Tab label="HVAC System Upgrades" value="3" />
                              <Tab label="Central Plant Upgrades" value="4" />
                              <Tab label="Water Resource Management " value="5" />
                              <Tab label="Building Envelope" value="6" />
                              <Tab label="MEP Engineering" value="7" />
                              <Tab label="Deep Energy Retrofits" value="8" /> 
                            </TabList>
                          </Box>
                        
                          </div>
                        <div className=' col-md-12 col-sm-12 col-xs-12' >
                          <TabPanel value="1">
                                <div className='table_design_panel02'>
                                  <div className='row one_inner_light'>
                                        <div className='col-md-6'>
                                          <h4 className='tab_title_de'> Interior Lighting and <br/>Control Enhancements </h4>
                                           
                                        </div> 

                                        <div className='col-md-6'> 
                                          <p>METCO Engineering is a reliable name in the energy sector. The company provides a viable and budget-friendly control enhancements system for improved light consumption and savings.</p>
                                        </div>


                                  </div>

                                  <div className='row one_inner_light'>
                                        <div className='col-md-6'>
                                          <div  className='tab_title_img'>
                                            <img src={`${process.env.PUBLIC_URL}/assets/img/table01.jpg`} /> 
                                          </div>
                                           
                                        </div> 


                                        <div className='col-md-6'>
                                          <div  className='tab_title_descr'>
                                            <h5> What is Interior Light and Control Enhancements? </h5>
                                            <p>Lighting is one of the most important aspects in a decor as it sets the mood and ambiance of the place. Modern lighting has a control mechanism for dimming or brightening. Controlled lights improve the room's ambience and save power expenditures.</p>
                                          </div>
                                           
                                        </div> 


                                  </div>

                                  <div className='row one_inner_light one_inner_light_right '>
                                        


                                        <div className='col-md-6'>
                                          <div  className='tab_title_descr'>
                                            <h5> METCO’s Lighting and Control Enhancements </h5>
                                            <p>METCO provides a range of advanced control systems that give users complete control over light intensity. The technology optimizes energy savings and meets green living requirements and other building design and construction energy conservation guidelines. A single user interface device may control a single or a group of lights and build elaborate sceneries for certain activities.</p>
                                          </div>
                                           
                                        </div> 

                                        <div className='col-md-6'>
                                          <div  className='tab_title_img'>
                                            <img src={`${process.env.PUBLIC_URL}/assets/img/table02.jpg`} /> 
                                          </div>
                                           
                                        </div> 




                                  </div>



                                   
                                </div>


                          </TabPanel>
                          <TabPanel value="2">
                          <div className='table_design_panel'>
                                  <h3>Analysis-Based Construction</h3>
                                  <p>Our team of professional engineers first assess your power requirements. Following  a thorough evaluation, we suggest infrastructure improvements, energy optimisation, and management or conservation techniques to make your business sustainable in the long run.</p>
                                  <a href='' className='btn btn-primary'>View More</a>
                                </div>


                          </TabPanel>
                          <TabPanel value="3">
                          <div className='table_design_panel'>
                                  <h3>Weather Normalization</h3>
                                  <p>Our team of professional engineers first assess your power requirements. Following  a thorough evaluation, we suggest infrastructure improvements, energy optimisation, and management or conservation techniques to make your business sustainable in the long run.</p>
                                  <a href='' className='btn btn-primary'>View More</a>
                                </div>


                          </TabPanel>
                        </div>
                      </TabContext>
                    </div>
                     
                </div>
            </div>
        </section>




        <section className="energy_efficientcy_tabs engery_setting_tabs">
            <div className="container-fluid">
                <div className="box_left_menu_s ">
                <div className=' col-md-12 col-sm-12 col-xs-12' >
                   <h2 className='section_title'>Energy settings available are</h2>
                </div>
                
                    <div className="row">
                      <div className="col-md-12">
                      <div class="featured-content-banner">
                             
                            <ul class="featured-panels">
                                <li class="featured-panel active"   >
                                    <div class="panel-img">
                                        <img  class="image_one"  src={`${process.env.PUBLIC_URL}/assets/img/tab01.jpg`} />    
                                        {/**<img class="image_two" src="new_img/Jackson Hewitt_Website Defaultlogo.png" />  */}
                                        <div class="panel-text01">
                                          <h3 class=" h3 verticle_text">Lights with Dimmers</h3>

                                        </div>
                                        <div class="panel-text">
                                           
                                            <h3 class=" h3">Lights with Dimmers</h3>
                                            <p>Intruder alarms can be paired with lights to increase safety by turning on all the lights at once and alerting law enforcement agencies and security companies. With such exact controls, the area may be designed for optimal utility and comfort.</p>
                                        </div>
                                    </div>
                                </li>

                                <li class="featured-panel">
                                    <div class="panel-img">
                                    <img  class="image_one"  src={`${process.env.PUBLIC_URL}/assets/img/tab02.jpg`} />  
                                    <div class="panel-text01">
                                          <h3 class=" h3 verticle_text">Dynamic Lights</h3>

                                        </div>
                                        <div class="panel-text">
                                           
                                            <h3 class="h3">Dynamic Lights</h3>
                                            <p>Intruder alarms can be paired with lights to increase safety by turning on all the lights at once and alerting law enforcement agencies and security companies. With such exact controls, the area may be designed for optimal utility and comfort.</p>
                                        </div>
                                    </div>
                                </li>

                                <li class="featured-panel">
                                    <div class="panel-img">
                                    <img  class="image_one"  src={`${process.env.PUBLIC_URL}/assets/img/tab03.jpg`} />  
                                    <div class="panel-text01">
                                          <h3 class="h3 verticle_text">Programmable Logic Controls</h3>

                                        </div>
                                        <div class="panel-text">
                                           
                                            <h3 class=" h3">Programmable Logic Controls</h3>
                                            <p>Intruder alarms can be paired with lights to increase safety by turning on all the lights at once and alerting law enforcement agencies and security companies. With such exact controls, the area may be designed for optimal utility and comfort.</p>
                                        </div>
                                    </div>
                                </li>

                                <li
                                    class="featured-panel"
                                >
                                    <div class="panel-img">
                                    <img  class="image_one"  src={`${process.env.PUBLIC_URL}/assets/img/tab04.jpg`} />  
                                    <div class="panel-text01">
                                          <h3 class="  h3 verticle_text">Occupancy Sensors</h3>

                                        </div>
                                        <div class="panel-text">
                                           
                                            <h3 class=" h3">Occupancy Sensors</h3>
                                            <p>Intruder alarms can be paired with lights to increase safety by turning on all the lights at once and alerting law enforcement agencies and security companies. With such exact controls, the area may be designed for optimal utility and comfort.</p>
                                        </div>
                                    </div>
                                </li>

                                <li class="featured-panel">
                                    <div class="panel-img">
                                    <img  class="image_one"  src={`${process.env.PUBLIC_URL}/assets/img/tab05.jpg`} />  
                                    <div class="panel-text01">
                                          <h3 class="h3 verticle_text">Alarm Settings</h3>

                                        </div>
                                        <div class="panel-text">
                                           
                                            <h3 class="h3">Alarm Settings</h3>
                                            <p>Intruder alarms can be paired with lights to increase safety by turning on all the lights at once and alerting law enforcement agencies and security companies. With such exact controls, the area may be designed for optimal utility and comfort.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                      </div>
                    </div>
                     
                </div>
            </div>
        </section>





        <section className="energy-settings">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                
              </div>
            </div>
          </div>
        </section>
        <section className="benefits">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  <h2>Benefits of Choosing METCO</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="details">
                  <div className="left">
                    <div className="icon"> 
                      <img src={`${process.env.PUBLIC_URL}/icon-1.png`} />
                    </div>
                  </div>
                  <div className="right"> 
                    <img src={`${process.env.PUBLIC_URL}/dotted.png`} />
                    <div className="text">
                     <div className="text-in">
                      <h4>Energy Efficient<br />Solution</h4>
                      <p>Dimmer switches control the amount of light being used for improved energy efficiency.</p>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="details">
                  <div className="left">
                    <div className="icon"> 
                      <img src={`${process.env.PUBLIC_URL}/icon-2.png`} />
                    </div>
                  </div>
                  <div className="right"> 
                    <img src={`${process.env.PUBLIC_URL}/dotted.png`} />
                    <div className="text">
                      <div className="text-in">
                        <h4>Low Electricity Bills</h4>
                        <p>Controlled light displacement reduces energy consumption which lowers electricity bills.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="details">
                  <div className="left">
                    <div className="icon"> 
                      <img src={`${process.env.PUBLIC_URL}/icon-3.png`} />
                    </div>
                  </div>
                  <div className="right"> 
                    <img src={`${process.env.PUBLIC_URL}/dotted.png`} />
                    <div className="text">
                      <div className="text-in">
                        <h4>Conserve Energy</h4>
                        <p>With automatic occupancy sensors, energy is never wasted.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="details">
                  <div className="left">
                    <div className="icon"> 
                      <img src={`${process.env.PUBLIC_URL}/icon-4.png`} />
                    </div>
                  </div>
                  <div className="right"> 
                    <img src={`${process.env.PUBLIC_URL}/dotted.png`} />
                    <div className="text">
                      <div className="text-in">
                        <h4>Customized Control</h4>
                        <p>Customize the brightness and quality of the light as per the room’s setting.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="btm-border"></div>
        <section className="led-lighting">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  <h2>Conversion to LED Lighting</h2>
                  <p>METCO offers a sustainable light source through its LED lighting system that is powerful and energy-efficient. With these bulbs, consumers get affordable and budget-friendly solution having multiple years of lifespan.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-sm-6">
                  <div className="img"> 
                    <img src={`${process.env.PUBLIC_URL}/lighting.png`} />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-sm-6">
                  <div className="text">
                    <h3>Why Switch to LED lights?</h3>
                    <p>Despite its numerous advantages, LED bulbs cost nearly $5, making them more pricey than incandescent lamps. As a leading engineering firm METCO provides energy-efficient solutions. We believe that LEDLEP lighting conversion is a popular investment worldwide. Which light is right for you depends on cost, performance, longevity, and durability. METCO promotes energy-efficient LED lighting. We believe LED setup as a costly but potential investment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="metco-leds">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  <h2>Switching to METCO LEDs<br />offers following benefits</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led1.png`} />
                  <div className="heading">
                    <h4>Durable Structure</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led2.png`} />
                  <div className="heading">
                    <h4>Directional lighting</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led3.png`} />
                  <div className="heading">
                    <h4>Environmentally safe</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led4.png`} />
                  <div className="heading">
                    <h4>Diverse Color Range</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led5.png`} />
                  <div className="heading">
                    <h4>Multiple Application</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
 
     

    </>
  )
}

export default BusinessTest