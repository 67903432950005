import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; 
import apiService from "../utils/apiService";


const useMetadata = () => {
  const location = useLocation();
  const [metadata, setMetadata] = useState(null);
  const [error, setError] = useState(null);

  // Extract slug from URL
  const getSlug = () => {
    const segments = location.pathname.split("/").filter(Boolean);
    //return segments.length ? segments[segments.length - 1] : "";
    return segments.length ? segments[segments.length - 1] : "home"; // ✅ Default to "home"

  };

  const slug = getSlug();
  console.log(slug );
  console.log(slug + "slug");
  const meta_slug = `metadata/${slug}`;

  useEffect(() => {
    
    const fetchMetaDetails = async () => {
        
      try {
        const response = await apiService(meta_slug);
        setMetadata(response.data.meta_data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMetaDetails();
  }, [meta_slug]);

  return { metadata, error };
};

export default useMetadata;
