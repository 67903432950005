import React, { useState, useEffect } from 'react';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useMetadata from '../../hooks/useMetadata';


import BlogListCom from '../blog/BlogListCom';
import apiService from '../../utils/apiService';


import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


function CertificationsAndAffiliations(){
  
  const [about_data, setabout_data] = useState(null); 
    const [affiliations_partnerships      , setaffiliations_partnerships] = useState(null);
    const [aboutBanner    , setaboutBanner ] = useState(null);
    const [aboutcertifications , setaboutcertifications] = useState(null);
  
  
    const [error, setError] = useState(null);

    
  const getabout = async () => {
    try {
      const response = await apiService('certifications');
      setabout_data(response.data); 
      setaboutBanner(response.data.banner );  
      setaboutcertifications(response.data.certifications ); 
      setaffiliations_partnerships(response.data.affiliations_partnerships ); 

    } catch (err) {
      setError(err.message);
    }
   };

   const { metadata,  metaError } = useMetadata();



   
      useEffect(() => { 
         getabout();  
     
      }, [])


 
    return(
      
        <>
      
	{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}
	  

    <Headroom>
    <Header />
    </Headroom>


    {aboutBanner ? ( 
        <section className="inner-banner">  
            {aboutBanner.image && <img src={aboutBanner.image} alt={aboutBanner.name} />} 
            <div className="container-fluid">
                <h1>{aboutBanner.name}</h1>
            </div>
        </section>

) : null}

{aboutcertifications ? (  
       
        <section className="certifications">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  <h2>Certifications</h2>
                </div>

                
                <OwlCarousel className='owl-theme blog-slider' loop margin={15} nav responsive={{
                        0: {
                          items: 1, // Show 1 item on small screens
                        },
                        600: {
                          items: 2, // Show 2 items on medium screens
                        },
                        1000: {
                          items: 3, // Show 3 items on large screens
                        },
                      }}>

                    {aboutcertifications.map((solution) => (
                      
                      <div className="item"  key={solution.id}>
                          {solution.image_full_url && <img src={solution.image_full_url} alt={solution.title} />}
                      </div>
                                            
                    ))}
                   
                </OwlCarousel>
 
              </div>
            </div>
          </div>
        </section>
        

        
) : null}




{affiliations_partnerships ? ( 

         <section className="partners">
         <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="text">
                        <h2>Affiliations &<br />Partnerships</h2>
                        <div className="scroll">
                          <ul>
                          {affiliations_partnerships.map((affiliations) => (  
                              <li  key={affiliations.id} >{affiliations.title}</li> 
                            ))} 
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="hor-scroll">
                      {/* <div className="row">
                      {affiliations_partnerships.map((affiliation, index) => {
                          // Group items in pairs for two rows
                          if (index % 2 === 0) {
                            const nextAffiliation = affiliations_partnerships[index + 1]; // Get the next item if it exists
                            return (
                              <div className="item col-md-6 col-sm-6 col-xs-12" key={affiliation.id}>
                                <div className="clint">
                                  <img src={affiliation.image_full_url} alt={affiliation.title} />
                                </div>
                                {nextAffiliation && (
                                  <div className="clint">
                                    <img src={nextAffiliation.image_full_url} alt={nextAffiliation.title} />
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return null; // Skip rendering odd-indexed items, since they're included in pairs
                        })}

                        </div> */ }
                      {/*
                      <OwlCarousel className="owl-theme blog-slider clint-slider" loop margin={10} items={2}>
                        {affiliations_partnerships.map((affiliation, index) => {
                          // Group items in pairs for two rows
                          if (index % 2 === 0) {
                            const nextAffiliation = affiliations_partnerships[index + 1]; // Get the next item if it exists
                            return (
                              <div className="item" key={affiliation.id}>
                                <div className="clint">
                                  <img src={affiliation.image_full_url} alt={affiliation.title} />
                                </div>
                                {nextAffiliation && (
                                  <div className="clint">
                                    <img src={nextAffiliation.image_full_url} alt={nextAffiliation.title} />
                                  </div>
                                )}
                              </div>
                            );
                          }
                          return null; // Skip rendering odd-indexed items, since they're included in pairs
                        })}
                      </OwlCarousel> */ }

                      </div>
                    </div>
                  </div>

                  <div className='row'>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="">
                      <div className="row">
                      {affiliations_partnerships.map((affiliation, index) => {
                          // Group items in pairs for two rows
                          
                            
                            return (
                              <div className="item col-md-3 col-sm-3 col-xs-12" key={affiliation.id}>
                                <div className="clint">
                                  <img src={affiliation.image_full_url} alt={affiliation.title} />
                                </div>
                                 
                              </div>
                            );
                            
                        })}

                        </div> 

                      </div>
                    </div>

                  </div>
                  </div>
                </section> 
        
) : null}

<BlogListCom />

 
        </>
    )
}

export default CertificationsAndAffiliations