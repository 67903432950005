import React, { useState, useEffect } from 'react';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import useMetadata from '../../hooks/useMetadata';

import apiService from '../../utils/apiService';
import { Helmet } from "react-helmet";
import Banner from '../includes/Banner';

const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function Contact(){

  const [alert, setAlert] = useState({ message: "", type: "" }); // Holds alert message and type

    

    const [formResponsse, setformResponsse] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        job_title: "",
        company: "",
        industries: "",
        message: "",
      });
      const [error, setError] = useState(null);

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const fetchData = async (post_data) => {
        try {
          const response = await apiService("inquiries", "POST", post_data);
          setformResponsse(response.data.projects); // Process response data
        } catch (err) {
          setError(err.message);
        }
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
      
        const post_data = {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          subject: formData.subject,
          job_title: formData.job_title,
          company: formData.company,
          industries: formData.industries,
          message: formData.message, 
        };
      
        try {
          await fetchData(post_data);
          //alert("Inquiry submitted successfully!");
          setAlert({ message: "Inquiry submitted successfully!", type: "success" });

            // Optionally clear the form
            setFormData({
                name: "",
                email: "",
                phone: "",
                subject: "",
                job_title: "",
                company: "",
                industries: "",
                message: "",
            });
            
        } catch (err) {
          setAlert({
            message: `Failed to submit inquiry: ${err.message}`,
            type: "warning",
          });
    
          //alert(`Failed to submit inquiry: ${err.message}`);
        }
      };


      
  const { metadata,  metaError } = useMetadata();
    
      const [aboutBanner    , setaboutBanner ] = useState(null);
      const [contact_data    , setcontact_data ] = useState(null);

      const [customersData    , setcustomersData ] = useState(null);

          const getabout = async () => {
            try {
              const response = await apiService('contact'); 
              setaboutBanner(response.data.banner ); 
              setcustomersData(response.data);
            } catch (err) {
              setError(err.message);
            }
           };
        

    
const getcontact_details = async () => {
  try {
    const response = await apiService('contact');
    setcontact_data(response.data.contact_info); 
    setaboutBanner(response.data.banner );    

  } catch (err) {
    setError(err.message);
  }
 };

           
              useEffect(() => { 
                getabout();  
                getcontact_details();  
             
              }, [])
      


    return(
        <>

{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}
  
        <Headroom>
            <Header />
        </Headroom>

        
        {aboutBanner ? ( 
          
          <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner.name}   youtube_video_id={aboutBanner.youtube_video_id} />
   
        ) : null}
    
        
        <section className='contact-form-head'>
            <div className='form-head'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='head'>
                                <h2>{contact_data?.title}</h2>
                                <p dangerouslySetInnerHTML={{ __html: contact_data?.description }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='conact-form'>
                <div className='container-fluid'>
                      

                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12 offset-md-3 offset-sm-3">
                      {alert.message && (
                          <div
                            className={`alert alert-${alert.type} alert-dismissible fade show`}
                            role="alert"
                          >
                            {alert.message}
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                              onClick={() => setAlert({ message: "", type: "" })}
                            ></button>
                          </div>
                        )}

                </div>
                </div>


                <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form">
          <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 10) {
                    setFormData((prevData) => ({
                    ...prevData,
                    phone: value,
                    }));
                }
                }}
                required
                pattern="\d{10}"
                title="Please enter a valid 10-digit phone number"
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form">
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form">
            <input
              type="text"
              name="job_title"
              placeholder="Job TItle"
              value={formData.job_title}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form">
            <input
              type="text"
              name="company"
              placeholder="Company"
              value={formData.company}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="form">
            
            <select name="industries" placeholder="Industries" value={formData.industries} onChange={handleInputChange} required>
                <option value="" disabled >  Industries </option>
                <option value="Manufacturing" >  Manufacturing </option>
            </select>
          </div>
        </div>




        <div className="col-md-12">
          <div className="form">
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleInputChange}
              required
            ></textarea>
          </div>
        </div>
        <div className="col-md-12">
          <div className="submit-query">
            <input type="submit" value="Submit Your Enquiry" />
          </div>
        </div>
      </div>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
    </form>
                </div>
            </div>
        </section>
        <section className='map-area'>
            <div className='text'>
                <div className='address'>
                    <h5>Address</h5>
                    <p>{contact_data?.address}</p>
                </div>
                <div className='address'>
                    <h5>Email Address</h5>
                    <p>{contact_data?.email}</p>
                </div>
                <div className='address'>
                    <h5>Phone</h5>
                    <p>{contact_data?.phone_no}</p>
                </div>
            </div>
            <div className='right'>
                <div className='map embeded_map'  dangerouslySetInnerHTML={{ __html: contact_data?.gmaps || "<p>No map available</p>" }}>   
                </div>
            </div>
        </section> 
        </>
    )
}

export default Contact