import React, { useState, useEffect } from 'react';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Link} from 'react-router-dom'  
import useMetadata from '../../hooks/useMetadata';


import BlogListCom from '../blog/BlogListCom';

import apiService from '../../utils/apiService'; 
import { useSearchParams } from "react-router-dom"; 



import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


function Search(){
  
    const [searchParams] = useSearchParams();
  const query = searchParams.get("query") || "";

  const [aboutData, setAboutData] = useState([]);
  const [aboutProject, setAboutProject] = useState([]); // Fixed variable name
  const [error, setError] = useState(null);

  
  const { metadata,  metaError } = useMetadata();
    


  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!query) return; // Avoid making an empty API call
      try {
        const response = await apiService(`search?query=${query}`);
        setAboutData(response.data.business || []);
        setAboutProject(response.data.project || []); // Fixed variable name
      } catch (err) {
        setError(err.message);
        setAboutData([]);
        setAboutProject([]);
      }
    };

    fetchSearchResults();
  }, [query]); // Runs when query changes


    return(
      
        <>
       {metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}


    <Headroom>
    <Header />
    </Headroom>
    {/*<section  >
        <br/> <br/>
            <div className="container mt-4">
                <form onSubmit={handleSearch} className="d-flex">
                    <input
                    type="text"
                    className="form-control me-2"
                    placeholder="Enter keywords..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    />
                    <button className="btn btn-primary" type="submit">Search</button>
                </form>
                {error && <p className="text-danger mt-3">No result found</p>}
                <br/> <br/>
            </div>
            
    </section  > */} 


{aboutData?.length > 0 || aboutProject?.length > 0 ? (
     <>

    {aboutData?.length > 0 ? (

        <section className='search_result' >
        <div class="album py-5 bg-light">
                <div class="container">
                        <div class="row">
                            <div className="col-md-4"  >
                                <h4>Business       </h4>
                            </div>
                        </div>
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                    {aboutData.map((item) => (
                        <div className="col-md-4" key={item.id}>
                            <div className="card shadow-sm">
                            <Link to={`/business/${item.slug}`} >  
                                {/* item.image_full_url && (
                                     <img src={item.image_full_url} className="card-img-top" alt={item.title} />
                                ) */}
                            <div className="card-body">
                                <h5 className="card-title" dangerouslySetInnerHTML={{ __html: item.title }} /> 
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: item.content.length > 40 ? item.content.substring(0, 100) + "..." : item.content }}></p>
              
                            </div>
                            </Link>
                            </div>
                        </div>
                        ))}

                         
                        
                        
                    </div>
                </div>
            </div>

        </section>
        ) : null}

{aboutProject?.length > 0 ? ( 

<section className='search_result' >
<div class="album py-5 bg-light">
        <div class="container">
                <div class="row">
                    <div className="col-md-4"  >
                        <h4>Projects</h4>
                    </div>
                </div>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            {aboutProject.map((item) => (
                <div className="col-md-4" key={item.id}>
                    <div className="card shadow-sm">
                    {/* <Link to={`/business/${item.slug}`} >   */} 
                    {/* item.image_full_url && (
                                     <img src={item.image_full_url} className="card-img-top" alt={item.title} />
                                ) */}
                    <div className="card-body">
                        <h5 className="card-title" dangerouslySetInnerHTML={{ __html: item.title }} /> 
                        <p className="card-text" dangerouslySetInnerHTML={{ __html: item.content.length > 40 ? item.content.substring(0, 100) + "..." : item.content }}></p>
      
                    </div>
                    {/* </Link>  */}
                    </div>
                </div>
                ))}

                 
                
<br/> <br/> 
            </div>
        </div>
    </div>

</section>
) : null}


</>
) : (
  <div className="text-center py-5">
    <h4>No results found</h4>
  </div>
)}

        
       

        </>
    )
}

export default Search