import React, { useState, useEffect, useRef } from "react";
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";

import ProjectListCom from '../includes/projects/ProjectListCom';
import apiService from '../../utils/apiService';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Banner from "../includes/Banner";

import useMetadata from '../../hooks/useMetadata';


import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


function Customer(){

    const [aboutBanner    , setaboutBanner ] = useState(null);
    const [customersData    , setcustomersData ] = useState([]);
    const [error, setError] = useState(null);
    const [customersList1    , setcustomersList1] = useState(null);
    const [value1, setValue1] = useState('1');

    const [allCustomersData, setallCustomersData] = useState([]);
    const [scrolled, setScrolled] = useState(false);

    
     
    const get_details = async (slug) => {
        console.log(slug);
        console.log("slug");
        setallCustomersData([]);
        try { 
              const customerDetailResponse = await apiService(`customers/${slug}`);   
              setallCustomersData((prevData) => [...prevData, customerDetailResponse.data.customers]); 
              return null;  
          } catch (error) { 
              return null;  
          }
       
      };


  const getabout_s = async () => {
   
    try { 
        const response = await apiService('customers');
        setcustomersData(response.data.customers);   
        setaboutBanner(response.data.banners[0]);   
       /* const customerDetailPromises = response.data.customers.map(async (item) => {
            get_details(item.slug);  
        });
        */
 
      // Wait for all the promises to resolve
       // await Promise.all(customerDetailPromises); 
 
     // setallCustomersData(customerDetails);
    } catch (err) {
      setError(err.message);
    }
   };




   

    const tabContentRef = useRef(null);
   
     const handleChange1 = (event, newValue) => {
       setValue1(newValue);
      /* if (tabContentRef.current) {
         
        const topPosition = tabContentRef.current.getBoundingClientRect().top + window.scrollY - 100; // Subtract 10px
       window.scrollTo({ top: topPosition, behavior: 'smooth' });
   
   
       } */
     };


   


     const { metadata,  metaError } = useMetadata();
    
   
      useEffect(() => {
        console.log("useEffect triggered"); 
        setallCustomersData([]);
        getabout_s();  
       // getabout11();  
      }, [])

      console.log(customersData);


      useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 450) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        };
    
        //window.addEventListener("scroll", handleScroll);
        return () => {
         // window.removeEventListener("scroll", handleScroll);
        };
      }, []);


      useEffect(() => {
        if (tabContentRef.current) {
          tabContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, [value1]);

      

    return(
        <>
         
         {metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}
	  
	  

        <Headroom>
            <Header />
        </Headroom>

        { aboutBanner ? ( 
          <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner?.name}   youtube_video_id={aboutBanner.youtube_video_id} />
    ) : null }


        
 
        <section className={`custom_tbs ${scrolled ? "scrolled" : ""}`}>

        

        <TabContext  value={value1}>
        <div  className="sticky-header">
          <div className="container-fluid">
            <div className="box_left_menu_s ">
            

              <div className="row">


                <div className=' col-md-12 col-sm-12 col-xs-12' >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     
                    <TabList onChange={handleChange1} >
                       {customersData.map((tabs, index) => ( 
                          <Tab key={index} label={tabs.customer.title} value={index.toString()} />
                        ))}
                    </TabList> 

                  </Box>

                </div>

              </div>

            </div>

            </div>
            </div>

          <div  >
          <div ref={tabContentRef}>
            {customersData.map((businessTab1 , index) => (
                <TabPanel  key={index} value={index.toString()} > 
                     <section className='c-and-u'>

                            {businessTab1.customer ? ( 
                                <div className='top-block'>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-md-6 col-sm-6 col-xs-12 order-2 order-md-1'>
                                                <div className='text'>
                                                    <h2>{businessTab1.customer.title}</h2> 
                                                    <p dangerouslySetInnerHTML={{ __html: businessTab1.customer.content }} />
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-sm-6 col-xs-12 order-1 order-md-2 '>
                                                <div className='img customsImg'>  
                                                    {businessTab1.customer.image && <img src={businessTab1.customer.image} alt={businessTab1.customer.name} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                            ) : null}

{businessTab1.scopes && businessTab1.scopes.length > 0 ?  ( 
    <div >
                            {businessTab1?.scopes?.map((scopes2, index) => (
    <div className="bottom-block" key={index}>
        <div className="container-fluid">
            <div className="row">
                {index % 2 === 0 ? (
                    // Even index: Image Left, Text Right
                    <>
                        <div className="col-md-6 col-sm-6 col-xs-12 scrope_content">
                            <div className="img"> 
                                {scopes2.image && <img src={scopes2.image} alt={scopes2.name || "Image"} />}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 scrope_imge">
                            <div className="text">
                                <h2>{scopes2.title}</h2>
                                <p className="scroll" dangerouslySetInnerHTML={{ __html: scopes2.description }} />
                            </div>
                        </div>
                    </>
                ) : (
                    // Odd index: Text Left, Image Right
                    <>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="text">
                                <h2>{scopes2.title}</h2>
                                <p className="scroll" dangerouslySetInnerHTML={{ __html: scopes2.description }} />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 image_last">
                            <div className="img">
                                {scopes2.image && <img src={scopes2.image} alt={scopes2.name || "Image"} />}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    </div>
))}


                             </div>
                            ) : null}


</section>
                          
                            {businessTab1.commitments && businessTab1.commitments.length > 0 ?  ( 

                            <section className='commitments'>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='head' dangerouslySetInnerHTML={{ __html: businessTab1?.commitment_title_description }} />
                                               {/*  <h2>METCO’s Commitments</h2>
                                                <p>METCO offers a number of benefits to colleges and universities<br />as we strive for improving education for a better future. </p> */ }

                                              
                                        </div>
                                    </div>
                                    <div className='row'>

                                    {businessTab1.commitments.map((commitment) => (
                                            
                                                <div className='col-md-4 col-sm-4 col-xs-12'  key={commitment.id}>
                                                <div className='commitments_item'>
                                                    <div className='img'> 
                                                    {commitment.image_full_url && <img src={commitment.image_full_url} alt={commitment.title} />}
                                                    </div>
                                                    <div className='text'>
                                                        <h3 dangerouslySetInnerHTML={{ __html: commitment.title }} />
                                                        <p className='descipt01'  dangerouslySetInnerHTML={{ __html: commitment.short_description }} /> 
                                                        <div className='exp-more'>
                                                            <a href=''>Explore More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>



                                            
                                            ))}


                                    </div>
                                </div>
                            </section>

                                        
                            ) : null}




                           

                </TabPanel>
                ))}
          </div> 
 

 
          


 
</div>
        </TabContext>
      </section>
 
           
        </>
    )
}

export default Customer