import * as React from 'react';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'; // Correct import path for modules
import 'swiper/swiper-bundle.css';



import ProjectListCom from '../includes/projects/ProjectListCom';


function Test(){

    const items = [
        { id: 1, name: 'Item 1', content: 'Content for Item 1' },
        { id: 2, name: 'Item 2', content: 'Content for Item 2' },
        { id: 3, name: 'Item 3', content: 'Content for Item 3' },
      ];


    return(
        <>
        <Headroom>
            <Header />
        </Headroom>
        <Swiper
      modules={[Pagination]}
      spaceBetween={30}
      slidesPerView={1}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          // Add the name of the item to the bullet
          return `<span class="${className}">${items[index].name}</span>`;
        },
      }}
    >
      {items.map((item) => (
        <SwiperSlide key={item.id}>
          <h3>{item.content}</h3>
        </SwiperSlide>
      ))}
    </Swiper>

        </>
    )
}

export default Test