import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom' 
import apiService from "../../utils/apiService";

function Main() {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
const [formResponsse, setformResponsse] = useState(null);
const [error, setError] = useState(null);

  const [isVisible, setIsVisible] = useState(false);

  // Show/hide button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 300); // Show button if scrolled down 300px
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage("Please enter a valid email.");
      return;
    }

    const postData = { email };

    try {
      const response = await apiService("newsletter", "POST", postData);
      setMessage(response?.data?.projects || "Subscription successful!");
      setEmail(""); 
    } catch (err) {
      setError(err.message || "Something went wrong. Try again!");
    }
  };



  return (
    <>
        <footer >
          <div className='footer-in'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className="ft-row">
                    <div className='ft-col'>
                      <h5>Head Office</h5>
                      <p>11120 Petal St #B300 Dallas, <br/> TX 75238

 </p>
                      <p className='whatscontact'>Would you like more<br/>information? <a href="">Email us</a>,<br/>contact us via <a href="">WhatsApp</a><br/>  
or <a href="">request a quote</a></p>
                    </div>
                    <div className='ft-col'>
                      <h5>Business</h5>
                      <ul>
                        <li> <Link to="/business/streamlined-energy-efficiency" >  Energy Efficiency  </Link></li>
                                                                <li> <Link to="/business/renewable-energy" >  Renewable Energy  </Link></li>
                                                                <li> <Link to="/business/energy-infrastructure" >  Energy Infrastructure  </Link></li>
                                                                {/*<li> <Link to="/business/energy-supply-management" >  Energy Supply Management  </Link></li>  */}
                                                                <li> <Link to="/business/construction-and-design" >  Construction and Design  </Link></li>
                                                                <li> <Link to="/business/engineering-services" >  Engineering  </Link></li>
                                                                <li> <Link to="/business/consultation-services" >  Consultation Services  </Link></li>
                                                                <li> <Link to="/business/profitable-financing-options" >  Profitable Financing  </Link></li>
                                                                <li> <Link to="/business/smart-energy-solution-using-ai" >  Smart Energy Solution Using AI  </Link></li>
                      </ul>
                    </div>
                    <div className='ft-col'>
                      <h5>aBOUT us</h5>
                      <ul>
                       {/* <li><Link to="/about" >  Who We Are?  </Link>  </li>
                        <li><Link to="/about" >  Vision & Mission  </Link> </li>
                        <li><a href="">Our Story</a></li>
                        <li><a href="">Our Journey</a></li>
                        <li> <Link to="/research_and_development" >  Research & Development  </Link>  </li>
                        <li><Link to="/certifications_and_affiliations" >  Certifications & Affiliations  </Link>  </li>
                        <li><a href="">Live Dashboards</a></li>
                        <li>  <Link to="/projects" >  Projects  </Link> </li>
                        <li> <Link to='/blogs'> News & Resource center</Link> </li>
                        <li>  <Link to="/terms-conditions" >  Terms & Conditions  </Link> </li>
                        <li>  <Link to="/privacy-policy" >  Privacy Policy  </Link> </li> */}

                        <li><Link to="/about" >  Who We Are?  </Link>  </li>  
                        <li> <Link to="/research_and_development" >  Research & Development  </Link>  </li>
                        <li><Link to="/certifications_and_affiliations" >  Certifications & Affiliations  </Link>  </li> 
                        <li>  <Link to="/projects" >  Projects  </Link> </li>  


                      </ul>
                    </div>
                  </div>
                  <div className='ft-btm-row'>
                    <div className='ft-btm-col newsletter'>
                        <h5>get our<br/>newsletter</h5>
                        {/* <form>
                          <input type='text' placeholder='Your Email'/> 
                          <img src={`${process.env.PUBLIC_URL}/sub-btn.png`} />
                        </form> */ }

                           <form onSubmit={handleSubmit}>
                            <input
                              type="email"
                              placeholder="Your Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                            <button type="submit">
                              <img src={`${process.env.PUBLIC_URL}/sub-btn.png`} alt="Subscribe" />
                            </button>
                          </form>
                          {message && <p className="mesg_foor">{message}</p>}
                    </div>
                    <div className='ft-btm-col'>
                      <div className='social'>
                        <a href=''><i class="fa fa-linkedin" aria-hidden="true"></i>linkedin</a>
                        <a href=''><i class="fa fa-facebook" aria-hidden="true"></i>facebook</a>
                      </div>
                    </div>
                    <div className='ft-btm-col brn'>
                      <div className='social'>
                        <a href=''><i class="fa fa-twitter" aria-hidden="true"></i>TWITTER</a>
                        <a href=''><i class="fa fa-instagram" aria-hidden="true"></i>INSTAGRAM</a>
                      </div>
                    </div>
                  </div>
                  <div className='privecy'>
                    <p>Copyright © Metco Engineering 2025 - All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="chat" data-aos="fade-up" data-aos-duration="600">
          <ul>
            <li>
              <a href=""> 
                <img src={`${process.env.PUBLIC_URL}/message.png`} />
              </a>
              <a href="tel:+1 972-740-0361"> 
                <img src={`${process.env.PUBLIC_URL}/call.png`} />
              </a>
            </li>
          </ul>
        </div>

        {isVisible && (
        <button onClick={scrollToTop} className="top_bottom_tn" >  ↑ </button>
      )}

        
    </>
  )
}

export default Main