import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import useMetadata from '../../hooks/useMetadata';


import apiService from '../../utils/apiService';
 import Banner from '../includes/Banner';
 

const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function Career(){

    
  const [about_data, setabout_data] = useState(null);  
  const [aboutBanner    , setaboutBanner ] = useState(null); 


  const [error, setError] = useState(null);

  
  const { metadata,  metaError } = useMetadata();


const getabout = async () => {
  try {
    const response = await apiService('careers'); 
    setaboutBanner(response.data.banner );    

  } catch (err) {
    setError(err.message);
  }
 };


 
    useEffect(() => { 
       getabout();  
   
    }, [])


    const location = useLocation();
  const job = location.state?.job;





  const [alert, setAlert] = useState({ message: "", type: "" }); // Holds alert message and type

    

    const [formResponsse, setformResponsse] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        job: "",
        resume: null,
      });
       

      const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
          ...prevData,
          resume: file, // Save the file object
        }));
      };


      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const fetchData = async (post_data) => {
        try {
            for (let [key, value] of post_data.entries()) {
                console.log(`${key}: ${value}`);
              }
          const response = await apiService("job_application", "POST", post_data);
          setformResponsse(response.data.projects); // Process response data
        } catch (err) {
          setError(err.message);
        }
      };

      const handleSubmit = async (e) => {
        e.preventDefault();

        const postData = new FormData(); 
        postData.append("name", formData.name);
        postData.append("email", formData.email);
        postData.append("phone", formData.phone);
        postData.append("job", formData.job);
        postData.append("resume", formData.resume); 
        //console.log(postData);
 
        try {
          await fetchData(postData);
          //alert("Inquiry submitted successfully!");
          setAlert({ message: "Inquiry submitted successfully!", type: "success" });
 
        } catch (err) {
          setAlert({
            message: `Failed to submit inquiry: ${err.message}`,
            type: "warning",
          });
    
          //alert(`Failed to submit inquiry: ${err.message}`);
        }
      };

      

  if (!job) {
    return <p>No job details available</p>;
  }




    return(
        <>

        
    	
	{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}

        <Headroom>
            <Header />
        </Headroom>
         
        {/*
        <section className="inner-banner"> 
            <img src={`${process.env.PUBLIC_URL}/career-banner.png`} />
            <div className="container-fluid">
                <h1>Career</h1>
            </div>
        </section> */}

        {aboutBanner ? ( 
          <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner.name}   youtube_video_id={aboutBanner.youtube_video_id} />
    ) : null}

        {job ? (   

            
        <section className='contact-form-head'>
            <div className='form-head'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='head'>
                                <h2>Apply for :  {job.title}</h2>
                            </div>
                        </div>

                        {alert.message && (
                          <div
                            className={`alert alert-${alert.type} alert-dismissible fade show`}
                            role="alert"
                          >
                            {alert.message}
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                              onClick={() => setAlert({ message: "", type: "" })}
                            ></button>
                          </div>
                        )}


                        <div className='career-accordian'>
                            <Accordion  defaultActiveKey="1">
                            <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <div className='head-block'>
                                        <h3>{job.title}</h3>
                                        <p>{job.sub_heading}</p>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    <div className='conact-form'>
                                            <div className='container-fluid'>
                                                <form onSubmit={handleSubmit}>
                                                <input type='hidden' value={job.title} />
                                                    <div className='row'>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Your Name</label>
                                                                <input type='text' name="name"  value={formData.name}
              onChange={handleInputChange}
              />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Email Address</label>
                                                                <input type='text' name="email" value={formData.email}
              onChange={handleInputChange}
              />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Phone</label>
                                                                <input type='text' name="phone" value={formData.phone}
                onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 10) {
                    setFormData((prevData) => ({
                    ...prevData,
                    phone: value,
                    }));
                }
                }}
                
                pattern="\d{10}"/>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 col-sm-6 col-xs-12'>
                                                            <div className='form'>
                                                                <label>Upload Resume</label>
                                                                <input
          type="file"
          name="resume"
          onChange={handleFileChange}
          
        />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div className='submit-query'>
                                                                <input type='submit' value="Apply" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                             
                            </Accordion>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

) : null}


        </>
    )
}

export default Career