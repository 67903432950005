import React, { useState, useEffect } from 'react';
import { useInView } from "react-intersection-observer";

import Header from '../Header/newMain';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Skeleton } from '@mui/material';



import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'; 

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
import CountUp from "react-countup";

import Banner from '../includes/Banner';
import Headroom from 'react-headroom';
 
import apiService from '../../utils/apiService';

import { formatDate } from '../../utils/dateFormat';
 
import BlogListCom from '../blog/BlogListCom';
import ProjectListCom from '../includes/projects/ProjectListCom';

import useMetadata from '../../hooks/useMetadata';



const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


const Counter = ({ endValue, duration, shouldCount }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (shouldCount) {
      const increment = endValue / (duration / 50); // Calculate step
      let currentCount = 0;

      const timer = setInterval(() => {
        currentCount += increment;
        if (currentCount >= endValue) {
          clearInterval(timer);
          setCount(endValue);
        } else {
          setCount(Math.ceil(currentCount));
        }
      }, 50);

      return () => clearInterval(timer);
    }
  }, [shouldCount, endValue, duration]);

  return <span>{count}</span>;
};

function Home() {


  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the section is visible
  });

  const [sliderlist, setsliderlist] = useState(null);
  const [error, setError] = useState(null);
  const [hasVideo , sethasVideo ] = useState(false);

  const [about_data, setabout_data] = useState(null);
  const [blogList, setblogList] = useState(null);
  const [portfolioList, setportfolioList] = useState(null);

  const [homeData, sethomeData] = useState(null);
  const [homeDataAbout, sethomeDataAbout] = useState(null);
  const [homeDataLegentImpact, sethomeDataLegentImpact] = useState(null);
  const [homeDataSmartEnergy, sethomeDataSmartEnergy] = useState(null);
  const [homeDataLetsMake, sethomeDataLetsMake] = useState(null);
  const [homeDataLeadership, sethomeDataLeadership] = useState(null);
  const [homeDataservice, sethomeDataservice] = useState(null);
  const [homeConsultations, setConsultations] = useState(null);
   
 
  const [activeUuid, setActiveUuid] = useState(null);


  const fetchData = async () => {
    try {
      const response = await apiService('banners');
      setsliderlist(response.data);
       sethasVideo = sliderlist.some(item => item.video);
    } catch (err) {
      setError(err.message);
    }
  };

/*
  const getabout = async () => {
    try {
      const response = await apiService('aboutus');
      setabout_data(response.data[0]); 
    } catch (err) {
      setError(err.message);
    }
  }; */

  const getPortFolio = async () => {
    try {
      const response = await apiService('portfolios');
      setportfolioList(response.data.portfolios); 
    } catch (err) {
      setError(err.message);
    }
  };

  const getConsultations = async () => {
    try {
      const response = await apiService('consultations');
      setConsultations(response.data.consultations); 
    } catch (err) {
      setError(err.message);
    }
  };


  const home_blogs = async () => {
    try {
      const response = await apiService('blogs');
      setblogList(response.data); 
    } catch (err) {
      setError(err.message);
    }
  };

 

  const homes = async () => {
    try {
      const response = await apiService('home');
      sethomeData(response.data); 
      sethomeDataAbout(response.data.home_section1);
      sethomeDataLegentImpact(response.data.home_section2);
      sethomeDataSmartEnergy(response.data.home_section3);
      sethomeDataLetsMake(response.data.home_section4);
      sethomeDataLeadership(response.data.home_section5); 
      sethomeDataservice(response.data.services ); 
      setabout_data(response.data.home_section1 );  

    } catch (err) {
      setError(err.message);
    }
  };




 
  const getFirst50Words = (content, numberOfWords) => {
    const words = content.split(' ');
    const first50Words = words.slice(0, numberOfWords).join(' ');
    return first50Words;
  };



  const { metadata,  metaError } = useMetadata();
    
	// Ensure activeUuid is set only when portfolioList.data is available
useEffect(() => {
  if (portfolioList?.data?.length) {
    setActiveUuid(portfolioList.data[0].id);
  }
}, [portfolioList]);
  

  useEffect(() => {
    AOS.init(); 
    homes();
    fetchData();
  //  getabout();
    home_blogs();
    getPortFolio();
    getConsultations();


  }, []);



  return (
    <> 

{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}

    <Headroom>
    <Header />
    </Headroom>
<div className='main-banner'>
{sliderlist && sliderlist.length > 0 ? ( 
    <OwlCarousel className='owl-theme'  loop  margin={0}    items={1}  autoplay  autoplayTimeout={5000}>
 
        {sliderlist.map((item) => (
          
            <div class='item' key={item.id}>
              <div className='main_banner_item'> 
                  {item.video && <video src={item.video} poster={item.video_cover_image} autoPlay muted loop playsInline  />}
                  {item.image && <img src={item.image} />}
                  <div className='banner-text' data-aos="fade-up" data-aos-duration="600">
                    <div className='container'>
                      <h2 dangerouslySetInnerHTML={{ __html: item.name}} />  
                     {/* <div className='dis-btn'>
                      {item?.link && <a href={item.link}>Discover</a>}

                      </div> */} 
                    </div>
                  </div>
                  
              </div>
            </div>
 
        ))}
       
 
  </OwlCarousel> 
  ) :  
  <div className='container'> 
    <Skeleton variant="text"  width="100%" height="10vh"  />
    <Skeleton animation="wave" variant="rectangular" width="100%" height="50vh" />
  </div>
  }



   {/* <div class="whatsapp" data-aos="fade-up" data-aos-duration="600">
           
<div class="container" >
                <a target="_blank" href=""><i class="fa fa-facebook" aria-hidden="true"></i></a>
                <a target="_blank" href=""><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                <a target="_blank" href=""><i class="fa fa-linkedin" aria-hidden="true"></i></a>
              </div>
             
  </div> */} 
  </div>
 

 
  {homeDataAbout  ? ( 
       <section className='energy' data-aos="fade-up" data-aos-duration="600">
      
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='head'>
                <h2  dangerouslySetInnerHTML={{ __html: homeDataAbout.title }} /> 
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='text'>
                {/* <p className='bold'>Are you searching for long-term sustainable solutions that enhance your energy infrastructure and optimize energy efficiency ?</p>
                <p>METCO is a multi-faceted firm that, for over fifteen years, has been designing and delivering cost-effective energy solutions across Texas.  As an accredited Energy Services Company (ESCO), we can provide innovative financing options allowing you to reduce energy expenditure immediately with no money out of pocket.</p>    */}
                
                <div dangerouslySetInnerHTML={{ __html: getFirst50Words(homeDataAbout.content, 70) }} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='img'>  
                <img src={homeDataAbout.image} alt='home image' />
              </div>
            </div>
          </div>
        </div>
       </section>
      ) :  null}





{ homeDataLegentImpact  ? ( 
       <section className='impact' data-aos="fade-up" data-aos-duration="600"  ref={ref}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='head'>
                <h2 dangerouslySetInnerHTML={{ __html: homeDataLegentImpact.title }} />
              </div>
            </div>
          </div>

          {homeDataLegentImpact.data && homeDataLegentImpact.data.length > 0 ? ( 

          <div className='row'>
            
            {homeDataLegentImpact.data.map((data01) => (

            <div className='col-md-3 col-sm-6 col-xs-12'>
              <div className='legent-col'>
                <label>{data01.title}</label>
                <div className='text'>
                   
                  <h3> {data01.title === 'ENERGY EFFICIENCY' ? '$' : ''}
                  <Counter endValue={data01.number} duration={1000} shouldCount={inView} /> {data01.word} </h3>
                  
                  <p  dangerouslySetInnerHTML={{ __html: data01.content }} />
                </div>
              </div>
            </div>

          ))} 

 
          </div>

           ) :  null}

        </div>
       </section>

) :  null }

{/*
<section className='impact' data-aos="fade-up" data-aos-duration="600" ref={ref}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='head'>
                <h2>LEGEND IMPACT</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-3 col-sm-6 col-xs-12'>
              <div className='legent-col'>
                <label>ESG / SQ FT</label>
                <div className='text'>
                  <h3>
                  <Counter endValue={5.5} duration={1000} shouldCount={inView} />B+</h3>
                  <p>As a sustainability consulting advisor, Legence is maximizing the
                  efficiency of more than 5.5 billion</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6 col-xs-12'>
              <div className='legent-col'>
                <label>NET ZERO / SQ FT</label>
                <div className='text'>
                  <h3><Counter endValue={10} duration={1000} shouldCount={inView} /> M+</h3>
                  <p>As a sustainability consulting advisor, Legence is maximizing the
                  efficiency of more than 5.5 billion</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6 col-xs-12'>
              <div className='legent-col'>
                <label>ENERGY EFFICIENCY</label>
                <div className='text'>
                  <h3>$<Counter endValue={21} duration={1000} shouldCount={inView} /> B+</h3>
                  <p>As a sustainability consulting advisor, Legence is maximizing the
                  efficiency of more than 5.5 billion</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6 col-xs-12'>
              <div className='legent-col'>
                <label>GREEN CERTIFICATIONS</label>
                <div className='text'>
                  <h3><Counter endValue={1000} duration={1000} shouldCount={inView} /> +</h3>
                  <p>As a sustainability consulting advisor, Legence is maximizing the
                  efficiency of more than 5.5 billion</p>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>

  */}
{portfolioList  ? (      
       <section className='portfolio' data-aos="fade-up" data-aos-duration="600">
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='portfolio-head'>
                 
                 <div className='row'>
                    <div className='col-md-5'><h2>{portfolioList.title}</h2></div>
                    <div className='col-md-7'>
                      <div dangerouslySetInnerHTML={{ __html: portfolioList.content }} />
                    </div>
                  </div>


              </div>
              <div className='accordian'>
              <Accordion className={activeUuid}              
              preExpanded={[portfolioList.data[0]?.id]}
              /*preExpanded={activeUuid ? [activeUuid] : []} */ 
              onChange={(activeItems) => setActiveUuid(activeItems[0] || null)}

              >
              {portfolioList.data.map((portfol , index) => (
                <AccordionItem
                key={portfol.id} 
                uuid={portfol.id}
                style={{
                  backgroundImage: `url(${portfol.image_full_url})`, 
                    
                 }}>
                    <AccordionItemHeading className={activeUuid === portfol.id ? "open_acc accordion__heading" : "close_acc accordion__heading"}   >
                        <AccordionItemButton className='localgovernment' >
                        {portfol.title} 
                        <img src={`${process.env.PUBLIC_URL}/scroll1.png`} />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordion__panel">
                    <div className='port-detail'>
                              {portfol.image_full_url ? (
                                <img src={portfol.image_full_url} alt={portfol.title} />
                              ) : (
                                <img src={`${process.env.PUBLIC_URL}/assets/img/defaut_portfolio.jpg`}  alt="Default" />
                              )}
                          <div className='text'>
                            <h3> {portfol.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: getFirst50Words(portfol.content, 1000) }} />
                          </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                 ))} 
              </Accordion>
              </div>
            </div>
          </div>
        </div>
       </section> 
 
) :  null}

 
{homeDataservice  ? ( 

       <section className='services' data-aos="fade-up" data-aos-duration="600">
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='services-head'>
                  <div className='row'>
                    <div className='col-md-5'><h2>{homeDataservice.title}</h2></div>
                    <div className='col-md-7'>
                      <div dangerouslySetInnerHTML={{ __html: getFirst50Words(homeDataservice.content, 70) }} />
                    </div>
                  </div>
                
                
              </div>

              {homeDataservice.services && homeDataservice.services.length > 0 ? ( 

              <OwlCarousel className='owl-theme services-slider' loop margin={0} nav autoplay
              autoplayTimeout={2000} 
              responsive={{
                0: {
                  items: 1, // Show 1 item on small screens
                },
                600: {
                  items: 2, // Show 2 items on medium screens
                },
                1000: {
                  items: 3, // Show 3 items on large screens
                },
              }}
               >

                    {homeDataservice.services.map((service) => (

                                    <div class='item'  key={service.id}>
                                       <Link to={service.link}>  
                                          <div className='s_block'>
                                            <div className='img'> 
                                              {service.image_full_url && <img src={service.image_full_url} />} 
                                            </div>
                                            <div className='text'>
                                              <h3  dangerouslySetInnerHTML={{ __html: service.title}} />
                                              <div className='sertdxt' dangerouslySetInnerHTML={{ __html: service.content}} />  
                                              <div className='borders'></div>
                                            </div>
                                          </div>
                                        </Link>
                                    </div>

                    ))}
                
            </OwlCarousel>

            
          ) :  null}
            </div>
          </div>
        </div>
       </section>


      ) :  null}
 



 {homeDataSmartEnergy  ? ( 

 
       <section className='smart-energy' data-aos="fade-up" data-aos-duration="600">
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='text'>
                <div className='left'>
                  <h2 dangerouslySetInnerHTML={{ __html: homeDataSmartEnergy.title}} /> 
                 <div dangerouslySetInnerHTML={{ __html: homeDataSmartEnergy.content}} /> 
                  <div className='more'>
                    
                    {homeDataSmartEnergy.link && <a href={homeDataSmartEnergy.link}>Explore More</a>}  
                  </div>
                </div>
                <div className='right'> 
                  {homeDataSmartEnergy.image && <img src={homeDataSmartEnergy.image} />}  
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>

      ) :  null}  


<ProjectListCom />

    {/*
       <section className='metco-engenering' data-aos="fade-up" data-aos-duration="600">
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='head'>
                <h2><span>METCO</span> Engineering<br /><span>Projects</span></h2>
                <p>Here is a glimpse of our finished projects spread over a wide variety of multi-disciplinary fields. Our meticulous approach from execution to completion has earned us returning clients, company growth, and strong reputation in the energy sector.</p>
              </div>
              <div className='two-col'>
                <div className='small me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-1.png`} />
                  </div>
                  <div className='text'>
                    <h4>Engineering Services for Bachman<br />Recreation Centre</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
                <div className='large me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-2.png`} />
                  </div>
                  <div className='text'>
                    <h4>South Central Police station<br />HVAC Upgrades</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
              </div>
              <div className='btm-two-col'>
                <div className='large me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-3.png`} />
                  </div>
                  <div className='text'>
                    <h4>SHoston Airport System (HAS)<br />Energy Audit - IAH</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
                <div className='small me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-4.png`} />
                  </div>
                  <div className='text'>
                    <h4>City of Dallas Park and<br />Recereation ESPC</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
              </div>
              <div className='more'>
                <a href=''>Explore More</a>
              </div>
            </div>
          </div>
        </div>
       </section> */}

{homeConsultations && homeConsultations.length > 0 ? (

       <section className='consultation' data-aos="fade-up" data-aos-duration="600">
        <div className='container-fluid'>
          <div className='row'>

          {homeConsultations.map((homeConsultation) => (

            <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='details'>
                <div className='img'>  
                  {homeConsultation.image_full_url && <img src={homeConsultation.image_full_url} />}  
                </div>
                <div className='text'>
                  <h4 dangerouslySetInnerHTML={{ __html: homeConsultation.title}} />   
                  <div dangerouslySetInnerHTML={{ __html: getFirst50Words(homeConsultation.content, 17) }} />
                  <div className='more'>
                     
                     <Link to={homeConsultation.link} >  Explore More   </Link> 
                                                        
                  </div>
                </div>
                </div>
            </div>

))}
      {/*              <div className='col-md-6 col-sm-6 col-xs-12'>
              <div className='details'>
                <div className='img'> 
                  <img src={`${process.env.PUBLIC_URL}/consultation-2.png`} />
                </div>
                <div className='text'>
                  <h4>PROFITABLE FINANCING<br/>OPTIONS</h4>
                  <p>METCO offers custom-built financing alternatives, such as performance contracting (ESPC) and Design-Build Own Operate Maintain</p>
                  <div className='more'>
                    <a href=''>Explore More</a>
                  </div>
                </div>
                </div>
            </div> --->

            */}

          </div>
        </div>
       </section>

 ) :  null}  

       {homeDataLetsMake  ? ( 


       <section className='things' data-aos="fade-up" data-aos-duration="600">  
        {homeDataLetsMake.image && <img src={homeDataLetsMake.image} />}  
        <div className='text'>
          <h4 dangerouslySetInnerHTML={{ __html: homeDataLetsMake.title}} /> 
          <div dangerouslySetInnerHTML={{ __html: homeDataLetsMake.content}} /> 
          <div className='more'> 
            {homeDataLetsMake.link && <a href={homeDataLetsMake.link}>Let’s Work Together</a>}  
          </div>
        </div>
       </section>

  ) :  null}  


  
{homeDataLeadership  ? ( 



       <section className='leader-ship' data-aos="fade-up" data-aos-duration="600">
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='text-with-img'>
                <div className='img'> 
                 {homeDataLeadership.image && <img src={homeDataLeadership.image} />}  
                </div>
                <div className='text'>
                  <h3  dangerouslySetInnerHTML={{ __html: homeDataLeadership.title}} /> 
                  <div dangerouslySetInnerHTML={{ __html: homeDataLeadership.content}} />
                  <div className='more'> 
                    {homeDataLeadership.link && <a href={homeDataLeadership.link}>Explore More</a>}
                  </div>
                  <div className='top-arrow'> 
                    <img src={`${process.env.PUBLIC_URL}/top-arrow.png`} />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>

       

  ) :  null}

<BlogListCom />
      
       {/* blogList && blogList.length > 0 ? ( 
     
        
       
  

       <section className='blog' data-aos="fade-up" data-aos-duration="600">
        <div className='container-fluid'>
          <div className='row'>
            <div class="col-md-12">
              <div className='head'>
                <h2><span className='title_blog'>INSIGHTS Blogs</span> <span className='short_title_blog'>News</span></h2>
                <a href=''>View All   <img src={`${process.env.PUBLIC_URL}/right-arrow.png`} /></a>
              </div>
              <OwlCarousel className='owl-theme blog-slider' loop margin={40} nav>

              {blogList.map((blog) => (
                
                
                <div class='item' key={blog.id}> 
                  <div className='details'>
                    <div className='img'>
                        {blog.image ? (
                          <img src={blog.image} alt={blog.title} />
                        ) : (
                          <img src={`${process.env.PUBLIC_URL}/assets/img/default-image.jpg`}  alt="Default" />
                        )}
                    </div>
                    <div className='text'>
                      <div className='top-row'>
                        <a href=''><span className='dot'></span>Blogs</a>
                        <label>{formatDate(blog.created_at)}</label>
                      </div>
                      <p>{blog.title}</p>
                      <div className='more'> 
                        <Link to={`/blog/${blog.slug}`}> Read More  <img src={`${process.env.PUBLIC_URL}/blog-arrow.png`} />  </Link>
                      </div>
                    </div>
                  </div>
                </div>



            ))}

             
            </OwlCarousel>
            </div>
          </div>
        </div>
       </section>

) :  null */}


    </>
  )
}

export default Home