import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Header from '../Header/newMain';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import Headroom from 'react-headroom';
import apiService from '../../utils/apiService';

import { formatDate } from '../../utils/dateFormat';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Banner from '../includes/Banner';


import useMetadata from '../../hooks/useMetadata';



import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};



function BusinessWithCat() {
  
  const { category } = useParams(); 
  
  const [value, setValue] = useState('1');
  const [value1, setValue1] = useState('1');
  const [TabSectionTitle, setTabSectionTitle] = useState(null);
  const [last_tab_id, setlast_tab_id] = useState();
  const [businessData, setbusinessData] = useState(null);
  const [bannerList, setbannerList] = useState(null);
  const [businesses_solution, setbusinesses_solution] = useState(null);
  const [efficiency_solutions, setefficiency_solutions] = useState(null);
  const [solutionsTabs, setsolutionsTabs] = useState(null);
  const [solutionsTabsBg, setsolutionsTabsBg] = useState(null);
  const [metcoData, setmetcoData] = useState(null);
  const [benefitList, setBenefitList] = useState(null);
  const [error, setError] = useState(null);

  const tabsRef_sticky_head = useRef(null);

  const [tabsHeight, setTabsHeight] = useState(0);


  const [businessTabs, setbusinessTabs] = useState(null);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);

  };
 


  const tabContentRef = useRef(null);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);

   

    if (tabContentRef.current) {
     // tabContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

     const topPosition = tabContentRef.current.getBoundingClientRect().top + window.scrollY - tabsHeight + 10; // Subtract 10px
      window.scrollTo({ top: topPosition, behavior: 'smooth' });


    }
  };

  const page_api_path =  'business/' + category; 
  const page_api_path_tabs =  'business_tabs/' + category; 
 // console.log(page_api_path);

  const fetchData = async () => {
    try {
       
      const response = await apiService(page_api_path);
      setbusinessData(response.data);
      setbannerList(response.data.banners);
      setbusinesses_solution(response.data.section1);
      //setefficiency_solutions(response.data.businesses[0].efficiency_solutions);
      setsolutionsTabs(response.data.section2);
      setsolutionsTabsBg(response.data.section2_background);
      //setBenefitList(response.data.section3);
      
      if(response.data.section2){
        //setlast_tab_id(response.data.section2[0].id);
       

        if (response.data && response.data.section2 && response.data.section2[0] && response.data.section2[0].id) {
          var tabID = response.data.section2[0].id;
          setValue(tabID.toString());
      }


      }

      
    
      setmetcoData(response.data.section3);
      console.log("data requested");
      console.log(response.data.section3);
      //  sethasVideo = sliderlist.some(item => item.video);
    } catch (err) {
      setError(err.message);
    }
  };


  const get_business_tabs = async () => {
    setbusinessTabs(null);
    try {
      const response = await apiService(page_api_path_tabs);
      setbusinessTabs(response.data.business_tabs);

      if(response.data.business_tabs.length > 0){
        //setlast_tab_id(response.data.section2[0].id);
        var tabID1 = response.data.business_tabs[0].id;
        
        setValue1(tabID1.toString());

        var tab_section_title = response.data.tab_title;
        setTabSectionTitle(tab_section_title);

        if (tabsRef_sticky_head.current) {
          setTabsHeight(tabsRef_sticky_head.current.clientHeight);
          console.log(tabsRef_sticky_head.current.clientHeight)
          console.log("lnews");
        } 

      }

    } catch (err) {
      setError(err.message);
    }
  };

  
  const { metadata,  metaError } = useMetadata();
    

  


  useEffect(() => {

  console.log(`Fetching data for category: ${category}`);

    AOS.init();
    fetchData();
    get_business_tabs();

    
    
  }, [category])


  
  useEffect(() => {
    if (tabsRef_sticky_head.current) {
      setTabsHeight(tabsRef_sticky_head.current.clientHeight);
      console.log(tabsRef_sticky_head.current.clientHeight)
      console.log("lnews");
    } 
    console.log("dnoe tno");
  }, []);
  
  const [isSticky, setIsSticky] = useState(false);
    const tabRef = useRef(null);
    
    

    useEffect(() => {
      let lastScrollY = window.scrollY;
    
      const handleScroll = () => {

        if (!tabRef.current) return;
        
         const tabOffsetTop = tabRef.current.offsetTop;
        const currentScrollY = window.scrollY;
        console.log("tabOffsetTop " +  tabOffsetTop)
        console.log("currentScrollY " +  currentScrollY)
      //  console.log("lastScrollY " +  lastScrollY)

        if ( currentScrollY >  tabOffsetTop +100) {
       //   console.log("Scrolling Down");
        setIsSticky(true);
        } else {
       //  console.log("Scrolling Up");
          setIsSticky(false);
        }
        
    
        //if (currentScrollY > lastScrollY && currentScrollY >= tabOffsetTop) {
        if (currentScrollY > lastScrollY && currentScrollY >=  tabOffsetTop) {
          // Scrolling down & reaching the section -> Make it sticky
        //  console.log("Make it sticky") 
         // setIsSticky(true);
        } 
        else if (currentScrollY < lastScrollY && currentScrollY < tabOffsetTop) {
          // Scrolling up & moving above the section -> Remove sticky.
          //console.log("Remove sticky")
          //setIsSticky(false);
        }
    
        lastScrollY = currentScrollY;
      };
    
     // window.addEventListener("scroll", handleScroll);
       
    }, []);
    
    
    





  return (
    <>

{metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}

      <Headroom>
        <Header />
      </Headroom>

      {/* bannerList ? (
        <section className="inner-banner">
          <img src={bannerList.image} alt="" />
          <div className="container-fluid">
            <h1 dangerouslySetInnerHTML={{ __html: bannerList.name }} />
          </div>
        </section>

      ) : null */}

    {/*bannerList ? ( 
    <Banner image={bannerList.image}  video={bannerList.video} name={bannerList.name} />
    ) : null */}

<Banner image={bannerList?.image}  video={bannerList?.video} name={bannerList?.name} />


      {businesses_solution ? (



        <section className="efficiency">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="text">
                  <h2 dangerouslySetInnerHTML={{ __html: businesses_solution.title }} />
                  <div dangerouslySetInnerHTML={{ __html: businesses_solution.content }} />

                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="img">
                  {businesses_solution.image && <img src={businesses_solution.image} alt={businesses_solution.title} />}  
                </div>
              </div>
            </div>
          </div>
        </section>

      ) : null}
 

  {/* solutionsTabs && solutionsTabs.length > 0 ? (
        <section className="sustaince_efficiency_tabs">
          <div className="container-fluid">
            <div className="box_left_menu ">
              <div className="row">
                <TabContext value={value}>
                  <div className=' col-md-6 col-sm-6 col-xs-12' >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {solutionsTabs.map((solution) => (
                          <Tab key={solution.id} label={solution.title} value={solution.id.toString()} > {solution.title} {solution.id}  </Tab>
                        ))}
                      </TabList>
                    </Box>

                  </div>
                  <div className=' col-md-6 col-sm-6 col-xs-12' >
                  {solutionsTabs.map((solution) => (
                      <TabPanel key={solution.id} value={solution.id.toString()}>
                        <div className="table_design_panel">
                          <h3>{solution.title}</h3>
                          <div className="tades_content" dangerouslySetInnerHTML={{ __html: solution.content }}></div>
                          <a href="" className="btn btn-primary">View More</a>
                        </div>
                      </TabPanel>
                    ))}


                  </div>
                </TabContext>
              </div>

            </div>
          </div>
        </section>

      ) : null */}

 
  {solutionsTabs && solutionsTabs.length > 0 ? (
        <section className="sustaince_efficiency_tabs new_tableList" 
        style={{
          backgroundImage: solutionsTabsBg?.image ? `url(${solutionsTabsBg.image})` : "none", 
        }}
        >
          <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
                <div className="section_head">
                  <h2 class="section_title">{solutionsTabsBg?.title}</h2>
                  <div class="text"  dangerouslySetInnerHTML={{ __html: solutionsTabsBg?.content }} />
                </div>
            </div>
          </div>
            {/* <div className="box_left_menu ">
              <div className="row">
              {solutionsTabs.map((solution) => (
                        
                    
                <div key={solution.id} class="service-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                  <div class="inner-box">
                      <div class="image-box">
                          <figure class="image"> 
                               {solution.image && <img src={solution.image} alt={solution.title} />} 
                          </figure>
                          <div class="icon-box">
                            <i class="icon flaticon-solar-panel"></i>
                            {solution.icon_image && <img src={solution.icon_image} alt={solution.title} />} 
                          </div>
                      </div>
                      <div class="content-box">
                          <h4 class="title">{solution.title}</h4>
                          <div class="text"  dangerouslySetInnerHTML={{ __html: solution.content }} />
                          
                      </div>
                  </div>
              </div>

))}


              </div>

            </div> */}






            <div className="box_left_menu ">
              <div className="row">
                <div className="col-md-12">
                    <OwlCarousel className='owl-theme'  loop  margin={30}    items={3} nav  autoplay  autoplayTimeout={5000}
                    
                    responsive={{
                      0: { items: 1 },  // Mobile screens (up to 576px)
                      576: { items: 2 }, // Small screens
                      768: { items: 3 }, // Medium screens 
                    }}
                    
                    >
                    
                          

                     {solutionsTabs.map((solution) => (  
                          <div key={solution.id} class="item ">
                            <div class="service-block">
                              <div class="inner-box">
                                  <div class="image-box">
                                      <figure class="image"> 
                                           {solution.image && <img src={solution.image} alt={solution.title} />} 
                                      </figure>
                                      <div class="icon-box">
                                        <i class="icon flaticon-solar-panel"></i>
                                         {solution.icon_image && <img src={solution.icon_image} alt={solution.title} />} 
                                      </div>
                                  </div>
                                  <div class="content-box">
                                      <h4 class="title">{solution.title}</h4>
                                      <div class="text"  dangerouslySetInnerHTML={{ __html: solution.content }} />
                                      
                                  </div>
                                </div>
                            </div>
                        </div>
          
          ))}

          


                          
                    
                      </OwlCarousel> 
                </div>
              </div>

            </div>





          </div>
        </section>

      ) : null}
 

      {metcoData ? (

        <section className="energy-star">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 col-sm-12 col-xs-12">
                <div className="img"> 
                  {metcoData.image && <img src={metcoData.image} alt={metcoData.title} />} 
                </div>
              </div>
              <div className="col-lg-5 col-sm-12 col-xs-12">
                <div className="text">
                  <div className="hand"> 
                    {metcoData.icon_image && <img src={metcoData.icon_image} alt={metcoData.title} />} 
                  </div>
                  <h2 dangerouslySetInnerHTML={{ __html: metcoData.title }}></h2>

                  <div className="custom_scroll scroll" dangerouslySetInnerHTML={{ __html: metcoData.content }}></div>
                </div>
              </div>
            </div>
          </div>
        </section>


      ) : null}

 
{businessTabs && businessTabs.length > 0 ? (
      <section  ref={tabRef} className={`energy_efficientcy_tabs ${isSticky ? "sticky" : ""}`} >
 
          <div className="container-fluid">
            <div className="box_left_menu_s ">
              <div className=' col-md-12 col-sm-12 col-xs-12' >
                {/*<h2 className='section_title'>Energy Efficiency Services 1111</h2>  */}
                {TabSectionTitle &&  <h2 className='section_title'>{TabSectionTitle}</h2>} 
              </div> 
            </div>

          </div>  
        <TabContext value={value1}>
        <div ref={tabsRef_sticky_head} className="sticky-header">
          <div className="container-fluid">
            <div className="box_left_menu_s ">
              
              <div className="row">


                <div className=' col-md-12 col-sm-12 col-xs-12' >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange1} >
                       {businessTabs.map((businessTab) => (
                          <Tab key={businessTab.id} label={businessTab.name } value={businessTab.id.toString()} />
                        ))}
                    </TabList>
                  </Box>

                </div>

              </div>

            </div>

          </div>
          </div>

          <div ref={tabContentRef}>
          {businessTabs.map((businessTab1) => (
          <TabPanel key={businessTab1.id} value={businessTab1.id.toString()} >
            <div className="container-fluid">
              <div className="box_left_menu_s "> 
                <div className="row"> 

                  <div className=' col-md-12 col-sm-12 col-xs-12' >

                    <div className='table_design_panel02'>
                    
                    <div  dangerouslySetInnerHTML={{ __html: businessTab1?.section1?.content }}   ></div>
   
                    </div>

                  </div>

                </div>

              </div>

            </div>

            {/* businessTab1?.section2?.length > 0 ? (

                    
<section className="energy_efficientcy_tabs engery_setting_tabs">
    <div className="container-fluid">
      <div className="box_left_menu_s ">
        <div className=' col-md-12 col-sm-12 col-xs-12' >
          <h2 className='section_title'>Energy settings available are</h2>
        </div>

        <div className="row">
          <div className="col-md-12">
         
            <ul class="accordion--home">
            {businessTab1.section2.map((section2s) => (
                <li class="slide" style={{
                  backgroundImage: `url(${section2s.image_full_url})`,

                
              }}  >
          
                    <a href="#" class="image--content" target="_blank">
                        <h2>{section2s.title}</h2> 
                  </a>
                  <div class="tl-content">
                    <p>
                    {section2s.content}
                    </p>
                  </div>
                </li>
  ))}
                 
            </ul>

          </div>
        </div>

      </div>
    </div>
  </section>

  

) : null */ }




              {businessTab1?.section2?.length > 0 ? (

                    
                          <section className="energy_efficientcy_tabs engery_setting_tabs">
                              <div className="container-fluid">
                                <div className="box_left_menu_s ">
                                  {businessTab1?.tab_section2_title?.title && ( 
                                    <div className=' col-md-12 col-sm-12 col-xs-12' >
                                      <h2 className='section_title'>{businessTab1?.tab_section2_title?.title }</h2>
                                    </div>
                                  )}


                                  <div className="row">
                                    <div className="col-md-12">
                                      <div class="featured-content-banner">

                                        <ul class="featured-panels">
                                        {businessTab1.section2.map((section2s) => (
                                          <li class="featured-panel active"   >
                                            <div class="panel-img"> 
                                              {section2s.image_full_url && <img src={section2s.image_full_url} alt={section2s.title} />} 
                                              {/**<img class="image_two" src="new_img/Jackson Hewitt_Website Defaultlogo.png" />  */}
                                              <div class="panel-text01">
                                                <h3 class=" h3 verticle_text">{section2s.title}</h3>

                                              </div>
                                              <div class="panel-text">

                                                <h3 class=" h3">{section2s.title}</h3>
                                                <p>{section2s.content}</p>
                                              </div>
                                            </div>
                                          </li>
                                          ))}
                                           
                                        </ul>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </div>
                            </section>

                            

) : null }


{/* businessTab1.section3 ? (
  <div  dangerouslySetInnerHTML={{ __html: businessTab1?.section3?.content }}   ></div>
) : null */ }



{businessTab1?.section3?.data?.length > 0 ? (


<div>
                <section class="benefits new_benefits">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="head"><h2>{businessTab1?.section3?.title}</h2></div>
                            </div>
                        </div>
                        <div class="row">
                        {businessTab1?.section3?.data.map((section3s) => (
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="details">
                                    <div class="left">
                                        <div class="icon">
                                          
                                          {section3s.image_full_url && <img src={section3s.image_full_url} alt={section3s.title} />} 
                                          </div>
                                    </div>
                                    <div class="right">
                                       <img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/dotted_1735190799.png" />
                                        <div class="text">
                                            <div class="text-in">
                                                <h4 dangerouslySetInnerHTML={{ __html: section3s.title }} />
                                                <div dangerouslySetInnerHTML={{ __html: section3s.content }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             ))}
                              
                             
                        </div>
                    </div>
                </section>
            </div>

 

) : null }



{/*
              <div>
                <section class="benefits">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="head"><h2>Benefits of Choosing METCO</h2></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="details">
                                    <div class="left">
                                        <div class="icon"><img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/icon-1_1735190766.png" /></div>
                                    </div>
                                    <div class="right">
                                       <img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/dotted_1735190799.png" />
                                        <div class="text">
                                            <div class="text-in">
                                                <h4>
                                                    Energy Efficient<br />
                                                    Solution
                                                </h4>
                                                <p>Dimmer switches control the amount of light being used for improved energy efficiency.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="details">
                                    <div class="left">
                                        <div class="icon"><img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/icon-2_1735190910.png" /></div>
                                    </div>
                                    <div class="right">
                                        <img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/dotted_1735190799.png" />
                                        <div class="text">
                                            <div class="text-in">
                                                <h4>Low Electricity Bills</h4>
                                                <p>Controlled light displacement reduces energy consumption which lowers electricity bills.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="details">
                                    <div class="left">
                                        <div class="icon"><img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/icon-3_1735190934.png" /></div>
                                    </div>
                                    <div class="right">
                                        <img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/dotted_1735190799.png" />
                                        <div class="text">
                                            <div class="text-in">
                                                <h4>Conserve Energy</h4>
                                                <p>With automatic occupancy sensors, energy is never wasted.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <div class="details">
                                    <div class="left">
                                        <div class="icon"><img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/icon-4_1735190967.png" /></div>
                                    </div>
                                    <div class="right">
                                        <img src="https://stagingbh8.beforegoinglive.com/metcoengineering/uploads/page/dotted_1735190799.png" />
                                        <div class="text">
                                            <div class="text-in">
                                                <h4>Customized Control</h4>
                                                <p>Customize the brightness and quality of the light as per the room’s setting.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> */}

 



          </TabPanel>

))}
 
</div>
        </TabContext>
      </section>


) : null }


 
     

    </>
  )
}

export default BusinessWithCat