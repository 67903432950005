import React from "react";
import { Skeleton } from "@mui/material";

const Banner = ({ image, video, name, youtube_video_id }) => {
  return (
    <section className="inner-banner">
      {youtube_video_id ? (
        <div className="youtube-video-container">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${youtube_video_id}?autoplay=1&controls=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={name || "Banner Video"}
          ></iframe>
        </div>
      ) : video ? (
        <video src={video} autoPlay muted>
          Your browser does not support the video tag.
        </video>
      ) : image ? (
        <img src={image} alt={name || "Banner"} />
      ) : (
        // Fallback skeleton when none of the props are available
        <div className='container'> 
            <Skeleton variant="text"  width="100%" height="10vh"  />
            <Skeleton animation="wave" variant="rectangular" width="100%" height="50vh" />
          </div>
      )}

      {name && (
        <div className="container-fluid">
          <h1>{name}</h1>
        </div>
      )}
    </section>
  );
};

export default Banner;
