import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BlogListCom from '../blog/BlogListCom';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination  , Navigation} from 'swiper/modules'; // Correct import path for modules
import 'swiper/swiper-bundle.css';


import ProjectListCom from '../includes/projects/ProjectListCom';

import Banner from '../includes/Banner';
import apiService from '../../utils/apiService';

import useMetadata from '../../hooks/useMetadata';

import { Helmet } from "react-helmet";

const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


 


function About(){
  


  
  const [about_data, setabout_data] = useState(null);
  const [about_data_about, setabout_data_About] = useState(null);
  const [about_data_our_core_values, setAbout_data_our_core_values] = useState(null);
  const [about_services_solution    , setabout_services_solution  ] = useState(null);
  const [aboutOurMmission , setaboutOurMmission  ] = useState(null);
  const [aboutOurVision , setaboutOurVision  ] = useState(null);
  const [aboutOurHistory , setAboutOurHistory  ] = useState(null);
  const [messagefrom , setmessagefrom ] = useState(null);
  const [dataTaams    , setdataTaams ] = useState(null);
  const [aboutMetco    , setaboutMetco ] = useState(null);
  const [aboutBanner    , setaboutBanner ] = useState(null);
  const [aboutProjects    , setaboutProjects ] = useState(null);


  const [error, setError] = useState(null);
  
  const getabout = async () => {
   try {
     const response = await apiService('aboutus');
     setabout_data(response.data); 
     setaboutBanner(response.data.banner ); 
     setabout_data_About(response.data.aboutus ); 
     setAbout_data_our_core_values(response.data.our_core_values ); 
     setabout_services_solution(response.data.services_solution  ); 
     setaboutOurMmission(response.data.our_mission  ); 
     setaboutOurVision(response.data.our_vision  );
     setAboutOurHistory(response.data.our_history);
     setmessagefrom(response.data.message_from);
     setdataTaams(response.data.teams );
     setaboutMetco(response.data.about_metco );
     setaboutProjects(response.data.projects );
   } catch (err) {
     setError(err.message);
   }
  };

  const slides = [
    { id: 1, name: "City of Dallas", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
    { id: 2, name: "Dallas Parks & Recreation", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
    { id: 3, name: "Houston Hobby Airport", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
    { id: 4, name: "Houston Bush International", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
    { id: 5, name: "City of Dallas", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
    { id: 6, name: "Dallas Parks & Recreation", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
    { id: 7, name: "Houston Hobby Airport", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
    { id: 8, name: "Houston Bush International", image: "one_project_image01.jpg", logo: "project_logo01.jpg" },
  ];

   
  const { metadata,  metaError } = useMetadata();
    
 
   useEffect(() => { 
      getabout();    
  
   }, [])
   


 
    return(
      
        <>
       {metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}


    <Headroom>
    <Header />
    </Headroom>

    {aboutBanner ? ( 
    <Banner image={aboutBanner.image} video={aboutBanner.video} name={aboutBanner.name} />
    ) : null}

    {/* aboutBanner ? ( 
        <section className="inner-banner">  
            {aboutBanner.image && <img src={aboutBanner.image} alt={aboutBanner.name} />} 
            <div className="container-fluid">
                <h1>{aboutBanner.name}</h1>
            </div>
        </section>

    ) : null */}

        {about_data_about ? (

        <section className="metco-inc">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="text">
                            <h2>{about_data_about.title}</h2>
                            <p className="bold">{about_data_about.sub_title}</p>
                            <div className="scroll"   dangerouslySetInnerHTML={{ __html: about_data_about.content }} />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="img">  
                          {about_data_about.image_full_url && <img src={about_data_about.image_full_url} alt={about_data_about.title} />} 
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
      ) : null}


      

{aboutOurHistory ? (

<section className="history">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <div className='head'>
          <div className='row'>
            <div className='col-md-5'><h2>{aboutOurHistory?.title}</h2></div>
            <div className='col-md-7'>
              <div dangerouslySetInnerHTML={{ __html: aboutOurHistory.content }} />
            </div>
          </div> 


        </div>
        <Tabs>
          <TabList>
          {aboutOurHistory.data.map((history) => (
                  <Tab key={history.id} label={history.title} value={history.id.toString()} >{history.title} </Tab>
          ))} 
          </TabList>
            {aboutOurHistory.data.map((history) => (
                                  <TabPanel key={history.id} value={history.id.toString()}>
                                     <div className='mt-50'>
                                        <div className='row'>
                                            <div className='col-md-6 col-sm-6 col-xs-12'>
                                              <div className='text' dangerouslySetInnerHTML={{ __html: history.content }} /> 
                                            </div>
                                            <div className='col-md-6 col-sm-6 col-xs-12'>
                                              <div className='img'>  
                                                {history.image_full_url && <img src={history.image_full_url} alt='' />} 
                                              </div>
                                            </div>
                                          </div>
                                      </div>

                                      
                                  </TabPanel>
                                ))}

          
        </Tabs>
      </div>
    </div>
  </div>
</section>


) : null}




    {about_data_our_core_values ? (

        <section className="core-value">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  {/* <h2 className='green'>{about_data_our_core_values.title}</h2>
                  <p>Our innovative team possesses a passionate force, keeping them motivated to accomplish several goals and successfully finish the projects. </p> */}

                  <div className='row'>
                    <div className='col-md-5'><h2>{about_data_our_core_values.title}</h2></div>
                    <div className='col-md-7'>
                      <div dangerouslySetInnerHTML={{ __html: about_data_our_core_values.content }} />
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div className="corelist">
            <div className="row">

            {about_data_our_core_values.data.map((our_core) => (
                        <div className="col-md-4 col-sm-4 colk-xs-12">
                          <div className="details">  
                            {our_core.image_full_url && <img src={our_core.image_full_url} alt={our_core.title} />}
                            <div className="heading">
                              <h4>{our_core.title}</h4>
                              <div dangerouslySetInnerHTML={{ __html: our_core.content }} /> 
                            </div>
                          </div>
                      </div>

                      
              ))}   
            </div>
            </div>
          </div>
        </section>

      ) : null}

{about_services_solution ? (

        <section className="services-solutions" style={{
          backgroundImage: `url(${about_services_solution.image})`,
        }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  {/* <h2>Services & Solutions</h2>
                  <p>METCO offers cost-effective, perpetually profitable electricity services for government, healthcare, commercial, and educational clients, and further aspires to foster a more sustainable future by promoting efficient, reliable, and easily accessible resources.</p> */}

                  <div className='row'>
                    <div className='col-md-5'><h2>{about_services_solution.title}</h2></div>
                    <div className='col-md-7'>
                      <div dangerouslySetInnerHTML={{ __html: about_services_solution.content }} />
                    </div>
                  </div> 


                </div>

                
                <div className="subhead">
                  <h3 dangerouslySetInnerHTML={{ __html: about_services_solution.data.title }} />
                </div>

                <div dangerouslySetInnerHTML={{ __html: about_services_solution.data.content }} />
               
               
               {/* <div className="service-list">
                  <ul>
                    <li><span>Engineering & Consulting<br />Services (MEP)</span></li>
                    <li><span>Engineering Systems & Control</span></li>
                    <li><span>Design Build (DB)</span></li>
                    <li><span>Design-Bid-Build (DBB)</span></li>
                    <li><span>Continuous Commisioning® (CC®)</span></li>
                    <li><span>Energy Savings Performance 
                    Contracts (ESPC)</span></li>
                    <li><span>Job Order Contracts (JOC)</span></li>
                    <li><span>Construction Managment (CM)</span></li>
                    <li><span>General Contracting (GC)</span></li>
                    <li><span>Building Information Modeling (BIM)</span></li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      ) : null}

{aboutOurMmission ? (

        <section className='mission-vission'>
          <div className='container-fluid'>
            <div className='row'>
            {aboutOurMmission ? (
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='details'>
                  <div className='img'> 
                  {aboutOurMmission.image_full_url && <img src={aboutOurMmission.image_full_url} alt={aboutOurMmission.title} />}
                  </div>
                  <div className='text'>
                    <h4>{aboutOurMmission.title} </h4>
                    <div className="scroll" dangerouslySetInnerHTML={{ __html: aboutOurMmission.content }} />

                  </div>
                  </div>
              </div>
            ) : null}


          {aboutOurMmission ? (
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='details'>
                  <div className='img'> 
                  {aboutOurVision.image_full_url && <img src={aboutOurVision.image_full_url} alt={aboutOurVision.title} />}
                  </div>
                  <div className='text'>
                    <h4>{aboutOurVision.title} </h4>
                    <div className="scroll" dangerouslySetInnerHTML={{ __html: aboutOurVision.content }} />

                  </div>
                  </div>
              </div>
            ) : null}


              
            </div>
          </div>
        </section>

      ) : null}
  
              
  

{messagefrom ? (
        <section className='ceo-message new_ceo_mesage'>
          <div className='container-fluid'>

          <OwlCarousel className='owl-theme' loop items={1} dots nav>

          
          
          {messagefrom.map((message) => (
           <div class='item'> 
            <div className='row'>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='img'>  
                    {message.image_full_url && <img src={message.image_full_url} alt={message.title} />} 
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='text'>
                    <h2>{message.title}</h2>
                    <label> 
                    <span dangerouslySetInnerHTML={{ __html: message.designation }} /> </label>
                    <div className='scroll'  dangerouslySetInnerHTML={{ __html: message.content }}>
                       
                    </div> 
                  </div>
                </div>
              </div>
           </div>

))}

{/*
<div class='item'> 
            <div className='row'>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/ceo-img.png`} /> 
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='text'>
                    <h2>Message from CEO</h2>
                    <label>BARRY BAGHERI<br />
                    CEO, P.E, CSE, ELE</label>
                    <div className='scroll'>
                      <p>METCO Engineering Inc. is an MEP & Systems Controls Engineering, Design, Construction, and Consultation firm, established in 2004. The company was chartered in Texas, with an aim to provide bonded energy saving guarantees, substantial value, and turn-key energy solutions through enhanced developmental and structural techniques.</p>
                      <p>We firmly believe, 'Advanced Energy Solutions support Sustainability,' and we are proud to be leading the shift toward a more authentic and viable energy efficient solution to meet the imminent needs of our customers at local, regional, and global levels.</p>
                      <p>We firmly believe, 'Advanced Energy Solutions support Sustainability,' and we are proud to be leading the shift toward a more authentic and viable energy efficient solution to meet the imminent needs of our customers at local, regional, and global levels.</p>
                    </div> 
                  </div>
                </div>
              </div>
           </div>

           <div class='item'> 
            <div className='row'>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/ceo-img.png`} /> 
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='text'>
                    <h2>Message from COO                    </h2>
                    <label>BARRY BAGHERI<br />
                    CEO, P.E, CSE, ELE</label>
                    <div className='scroll'>
                      <p> METCO Engineering Inc. is dedicated to energy efficiency and environmental sustainability across industries. METCO Engineering Inc. updates lighting, solar PV, and HVAC systems to minimize energy usage and operating expenses. Our devoted staff collaborate with customers to provide customized, green solutions. We seek to improve the environment and our customers' bottom lines via creative engineering and thorough execution. <br/><br/>

METCO Engineering Inc. is known for smoothly integrating cutting-edge technology into practical applications. Our initiatives have consistently reduced carbon footprints, demonstrating our environmental impact. Our complete solutions improve efficiency and sustainability by upgrading obsolete lighting systems with energy-efficient ones and installing cutting-edge solar panels.

</p>
                    </div> 
                  </div>
                </div>
              </div>
           </div>



           <div class='item'> 
            <div className='row'>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/ceo-img.png`} /> 
                  </div>
                </div>
                <div className='col-md-6 col-sm-6 col-xs-12'>
                  <div className='text'>
                    <h2>Message from Director of Business Development </h2>
                    <label>CLIFF BRADDOCK <br />
                    CEM, LEED AP</label>
                    <div className='scroll'>
                      <p>
                      As the Director of Business Development, I am privileged to work with a dedicated team focused on advancing sustainability and energy efficiency through innovative engineering solutions. Our core areas of expertise encompass energy-efficient lighting and HVAC upgrades, custom solar photovoltaic (PV) systems, and resilient energy microgrids. By leveraging cutting-edge technologies and smart controls, we enable our clients to conserve energy, reduce costs, and enhance their environmental impact. <br/><br/>
Together, we can create a brighter, more sustainable future.



                      </p>
                    </div> 
                  </div>
                </div>
              </div>
           </div>*/}


           


 
       </OwlCarousel>

            
          </div>
        </section>

) : null}


      {/**
        <section className='ceo-message'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='img'> 
                  <img src={`${process.env.PUBLIC_URL}/ceo-img.png`} /> 
                </div>
              </div>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='text'>
                  <h2>Message from CEO</h2>
                  <label>BARRY BAGHERI<br />
                  CEO, P.E, CSE, ELE</label>
                  <div className='scroll'>
                    <p>METCO Engineering Inc. is an MEP & Systems Controls Engineering, Design, Construction, and Consultation firm, established in 2004. The company was chartered in Texas, with an aim to provide bonded energy saving guarantees, substantial value, and turn-key energy solutions through enhanced developmental and structural techniques.</p>
                    <p>We firmly believe, 'Advanced Energy Solutions support Sustainability,' and we are proud to be leading the shift toward a more authentic and viable energy efficient solution to meet the imminent needs of our customers at local, regional, and global levels.</p>
                    <p>We firmly believe, 'Advanced Energy Solutions support Sustainability,' and we are proud to be leading the shift toward a more authentic and viable energy efficient solution to meet the imminent needs of our customers at local, regional, and global levels.</p>
                  </div>
                   <div className='circle'>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                  </div> 
                   </div>
              </div>
            </div>
          </div>
        </section> */}




{dataTaams ? (


        <section className='our-team'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='head'>
                  {/* <h2>our team</h2>
                  <p>Here is a glimpse of our finished projects spread over a wide variety of multi-disciplinary fields. Our meticulous approach from execution to completion has earned us returning clients, company growth, and strong reputation in the energy sector.</p>

                   */}

                  <div className='row'>
                    <div className='col-md-5'><h2>{dataTaams.name}</h2></div>
                    <div className='col-md-7'>
                      <div dangerouslySetInnerHTML={{ __html: dataTaams.shortdetails }} />
                    </div>
                  </div> 


                </div>
                <OwlCarousel className='owl-theme team-slider' nav loop margin={0} items={4} responsive={{
                        0: {
                          items: 1, // Show 1 item on small screens
                        },
                        600: {
                          items: 2, // Show 2 items on medium screens
                        },
                        1000: {
                          items: 4, // Show 3 items on large screens
                        },
                      }}>

                {dataTaams.data.map((team) => (

 
                  <div class='item'>
                    <div className='details'>
                      <div className='img'>  
                        {team.image_full_url && <img src={team.image_full_url} alt={team.title} />} 
                      </div>
                      <div className='text'>
                        <h4>{team.name}</h4>
                        <p>{team.designation}</p>  
                      </div>
                    </div>
                  </div>

                             
))}

 
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        


      ) : null}

         

 
        
        </>
    )
}

export default About