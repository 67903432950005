// utils/dateFormat.js
export const formatDate = (dateString) => {
    
    /*const parseAndFormatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const date = new Date(year, month - 1, day);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        });
      };
      
      // Usage
      const formattedDate = parseAndFormatDate('18/12/2024');
      return formattedDate; */

      const [day, month, year] = dateString.split('/');
      const date = new Date(`${year}-${month}-${day}`); // Use YYYY-MM-DD
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
      

  };
  