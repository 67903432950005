import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom' ;
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";

import apiService from '../../utils/apiService';

import { formatDate } from '../../utils/dateFormat';



function BlogListCom() {

   const [blogList, setblogList] = useState(null);
  const [error, setError] = useState(null);
 
  const home_blogs = async () => {
    try {
      const response = await apiService('blogs');
      console.log("response");
      console.log(response);
      setblogList(response.data); 
    } catch (err) {
      setError(err.message);
    }
  };

  
  
    useEffect(() => {
      AOS.init(); 
   
      home_blogs(); 
  
  
    }, [])


  return (
    <>
         
                {blogList && blogList.length > 0 ? ( 
              
                 
                
           
         
                <section className='blog' data-aos="fade-up" data-aos-duration="600">
                 <div className='container-fluid'>
                   <div className='row'>
                     <div class="col-md-12">
                       <div className='head'>
                         <h2><span className='title_blog'>INSIGHTS Blogs</span>  </h2> 
                         <Link to='/blogs'> View All  <img src={`${process.env.PUBLIC_URL}/right-arrow.png`} />  </Link>
                       </div>
                       <OwlCarousel className='owl-theme blog-slider footerbloglist' loop margin={20} nav 
                       responsive={{
                        0: {
                          items: 1, // Show 1 item on small screens
                        },
                        600: {
                          items: 2, // Show 2 items on medium screens
                        },
                        1000: {
                          items: 3, // Show 3 items on large screens
                        },
                      }}
                      >
         
                       {blogList.map((blog) => (
                         
                         
                         <div class='item' key={blog.id}> 
                           <div className='details'>
                             <div className='img'>
                                 {blog.image ? (
                                   <img src={blog.image} alt={blog.title} />
                                 ) : (
                                   <img src={`${process.env.PUBLIC_URL}/assets/img/default-image.jpg`}  alt="Default" />
                                 )}
                             </div>
                             <div className='text'>
                               <div className='top-row'>
                                 <a href=''><span className='dot'></span>Blogs</a>
                                 <label>{formatDate(blog.date)}</label> 
                               </div>
                               <p>{blog.title}</p>
                               <div className='more'> 
                                 

                                 <a href={blog.link} target="_blank" rel="noopener noreferrer">
                                 Read More  <img src={`${process.env.PUBLIC_URL}/blog-arrow.png`} />  
</a>
 
                               </div>
                             </div>
                           </div>
                         </div>
         
         
         
                     ))}
          
                     </OwlCarousel>
                     </div>
                   </div>
                 </div>
                </section>
         
) :  null}
    </>
  )
}

export default BlogListCom