import React, { useEffect } from 'react'
import { BrowserRouter , Route, Routes, useLocation } from 'react-router-dom';
import Footer from '../component/footer/Main'
import Home from '../component/home/homePage';
import Business from '../component/home/Business';  
import BusinessWithCat from '../component/home/BusinessWithCat'; 
import BusinessTest from '../component/home/BusinessTest';
import About from '../component/home/About';
import CertificationsAndAffiliations from '../component/home/CertificationsAndAffiliations';
import Projects from '../component/home/Projects';
import ProjectDetails from '../component/home/ProjectDetails';
import Blogs from '../component/home/Blogs';
import ResearchAndDevelopment from '../component/home/ResearchAndDevelopment';
import WhoWeAre from '../component/home/WhoWeAre';
import Customer from '../component/home/Customer'; 
import CustomerDetails from '../component/home/CustomerDetails';
import Career from '../component/home/Career';
import CareerDetails from '../component/home/CareerDetails';
import Contact from '../component/home/Contact';
import Test from '../component/home/Test';
import TermsConditions from '../component/home/TermsConditions';
import PrivacyPolicy from '../component/home/PrivacyPolicy';

import Search from '../component/home/Search';


function Index() {

  const basePath = process.env.PUBLIC_URL || '/';
  const location = useLocation();
  const path = location.pathname
  console.log('path::::::::',path)
  useEffect(() => {
    window.scroll(0, 0)
  }, [path]);

  return (
    <>
      <Routes> 
        {/*<Route path={`${basePath}`} element={<Home />} />  */}
        <Route path={`${process.env.PUBLIC_URL}`} element={<Home />} /> 
        <Route path='/' element={<Home />} />  
        <Route path="/business" element={<Business />} />
        <Route path="/business/:category" element={<BusinessWithCat />} /> 
         
        <Route path="/business_test" element={<BusinessTest />} /> 
        <Route path="/about" element={<About />} /> 
        <Route path="/certifications_and_affiliations" element={<CertificationsAndAffiliations />} /> 
        <Route path="/projects" element={<Projects />} /> 
        <Route path="/projects/:slug" element={<ProjectDetails />} /> 
        <Route path="/blogs" element={<Blogs />} /> 
        <Route path="/research_and_development" element={<ResearchAndDevelopment />} /> 
        {/*<Route path="/who_we_are" element={<WhoWeAre />} />    */}
        <Route path="/who_we_are" element={<WhoWeAre />} /> 
        <Route path="/our_customer" element={<Customer />} />
        <Route path="/our_customer/:slug" element={<CustomerDetails />} />
        <Route path="/test" element={<Test />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career_details" element={<CareerDetails />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/search" element={<Search />} />
      </Routes> 
      <Footer/>
      
      {/* } */}
    </>
  )
}

export default Index