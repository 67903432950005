import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main"; 

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import { useRef } from "react"; 
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import useMetadata from '../../hooks/useMetadata';



 
import apiService from '../../utils/apiService';
import Banner from '../includes/Banner';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


function ProjectDetails(){
  const [swiperReady, setSwiperReady] = useState(false);
  const thumbsSwiperRef = useRef(null);
  const thumbsSwiperRef1 = useRef(null);
  
  const { metadata,  metaError } = useMetadata();

  const [loading, setLoading] = useState(true);


  const { slug } = useParams(); 
  console.log(slug);
  const [homeProject, sethomeProject] = useState(null);
  const [homeImages, sethomeImages] = useState([]);
  const [error, setError] = useState(null);
     
  const page_api_path =  'project/' + slug; 
    const fetchData = async () => {
      try {
       
        const response = await apiService(page_api_path);
          sethomeProject(response.data.project); 
          sethomeImages(response.data.images); 
          setLoading(false); 
          setSwiperReady(true); 
        
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
  
    const aboutBanner = {
      image: `${process.env.PUBLIC_URL}/about-banner.png`,
      video: "",
      name: "Have A Look At Our Solar Success Stories",
      youtube_video_id: "",
    };

    

    
      useEffect(() => { 
         AOS.init(); 
        fetchData();
       
        
    
      }, [slug])

      useEffect(() => {
        if (!loading && swiperReady) {
          // Optional: Call swiper.update() here to refresh the Swiper instance if needed
        }
      }, [loading, swiperReady]); 


    return(
      
        <>
      	{metadata ? (
          <>
            <DynamicMeta
              title={metadata.meta_title}
              description={metadata.meta_description}
              keywords={metadata.meta_keywords}
            />
          </>
        ) : null}

    <Headroom>
    <Header />
    </Headroom>

 

        {/* aboutBanner ? ( 
          <Banner image={aboutBanner.image} video={aboutBanner.video} name={homeProject?.title}   youtube_video_id={aboutBanner.youtube_video_id} />
    ) : null */}






<section className='case_stu_sliders'>
    <div className='container'>
    {homeProject  ? (  
        <div className='row'>
          <div className='col-md-12'>
              <h1>{homeProject?.title}   <br/>  <br/> </h1>
              
          </div>
        </div> 

) :  null}

      <div className='row'>
          <div className='col-md-9'>

         


          <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      navigation={true}
                      thumbs={{ swiper: thumbsSwiperRef.current }}
                      modules={[Navigation, Thumbs]}
                      className="main-swiper"
                      initialSlide={1}
                    >

                    {homeImages.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image.image_full_url} alt={`Slide ${index + 1}`} />
                        </SwiperSlide>
                    ))}
                      
                    </Swiper> 
          </div>

          <div className='col-md-3'>
            <div className='thumbs_list'>
                  {/* Thumbnail Swiper */}
                  
                 
                    <Swiper
                onSwiper={(swiper) => (thumbsSwiperRef.current = swiper)}
                direction="vertical" // Set thumbnails to be vertical
                spaceBetween={10}
                slidesPerView={3} // Number of thumbnails visible
                watchSlidesProgress
                modules={[Thumbs]}
                className="thumbs-swiper"
                centeredSlides={true} // Keeps the active slide centered
                slideToClickedSlide={true} // Allows clicking to navigate
                initialSlide={1}
              >
               
               {homeImages.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image.image_full_url} alt={`Slide ${index + 1}`} />
                        </SwiperSlide>
                    ))}
              </Swiper>

 

            </div>
          </div>

      </div>
    

     

    </div>

</section>

 

{homeProject  ? ( 

        <section className=' projeclisting' data-aos="fade-up" data-aos-duration="600">
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12'> 
                      <div className='case_details' dangerouslySetInnerHTML={{ __html: homeProject?.content }} />
                  </div>
                </div>
              </div> 
            </section>

        
  ) :  null}

         
        
        </>
    )
}

export default ProjectDetails