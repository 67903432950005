import React, { useState, useEffect } from 'react';
import Headroom from "react-headroom";
import Header from '../Header/newMain';
import Main from "../footer/Main";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import BlogListCom from '../blog/BlogListCom';

import apiService from '../../utils/apiService';
import useMetadata from '../../hooks/useMetadata';



import { Helmet } from "react-helmet";
const DynamicMeta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};


function WhoWeAre(){
  

  
  const [about_data, setabout_data] = useState(null);
   const [error, setError] = useState(null);

  const getabout = async () => {
    try {
      const response = await apiService('aboutus');
      setabout_data(response.data[0]); 
    } catch (err) {
      setError(err.message);
    }
  };

  const { metadata,  metaError } = useMetadata();



  
    useEffect(() => { 
       getabout();  
  
    }, [])


    return(
      
        <>
      {metadata ? (
        <>
          <DynamicMeta
            title={metadata.meta_title}
            description={metadata.meta_description}
            keywords={metadata.meta_keywords}
          />
        </>
      ) : null}

    <Headroom>
    <Header />
    </Headroom>


        <section className="inner-banner"> 
            <img src={`${process.env.PUBLIC_URL}/about-banner.png`} />
            <div className="container-fluid">
                <h1>About</h1>
            </div>
        </section>
        <section className="metco-inc">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="text">
                            <h2>METCO Engineering Inc.</h2>
                            <p className="bold">An Energy Services And Sustainability Company</p>
                            <div className="scroll">
                              <p>METCO Engineering Inc., based in Dallas, Texas, is a leader in the energy and construction industries. As an Energy Services Company (ESCO) and General Contractor, we are transforming Texas and the United States toward a greener future.</p>
                              <p>As general contractors, we use the latest technologies to complete projects that exceed expectations, from public buildings to large residential developments. We focus on quality craftsmanship, attention to detail, and delivering on time and on budget.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="img"> 
                            <img src={`${process.env.PUBLIC_URL}/metco-inc-image.png`} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="core-value">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  <h2>Our Core Values</h2>
                  <p>Our innovative team possesses a passionate force, keeping them motivated to accomplish several goals and successfully finish the projects. </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led1.png`} />
                  <div className="heading">
                    <h4>Delivery of Excellence</h4>
                    <p>We recognize every project as an opportunity to build upon our reputation for quality, creativity, and the development of strong client relations.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led2.png`} />
                  <div className="heading">
                    <h4>Nurturing Growth</h4>
                    <p>We recognize every project as an opportunity to build upon our reputation for quality, creativity, and the development of strong client relations.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led3.png`} />
                  <div className="heading">
                    <h4>Engineering Opportunities</h4>
                    <p>We recognize every project as an opportunity to build upon our reputation for quality, creativity, and the development of strong client relations.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led4.png`} />
                  <div className="heading">
                    <h4>Environmentally Sustainable</h4>
                    <p>We recognize every project as an opportunity to build upon our reputation for quality, creativity, and the development of strong client relations.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 colk-xs-12">
                <div className="details"> 
                  <img src={`${process.env.PUBLIC_URL}/led5.png`} />
                  <div className="heading">
                    <h4>Passion</h4>
                    <p>We recognize every project as an opportunity to build upon our reputation for quality, creativity, and the development of strong client relations.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="services-solutions">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  <h2>Services & Solutions</h2>
                  <p>METCO offers cost-effective, perpetually profitable electricity services for government, healthcare, commercial, and educational clients, and further aspires to foster a more sustainable future by promoting efficient, reliable, and easily accessible resources.</p>
                </div>
                <div className="subhead">
                  <h3>Following are some of the energy services<br />and solutions provided by METCO:</h3>
                </div>
                <div className="service-list">
                  <ul>
                    <li><span>Engineering & Consulting<br />Services (MEP)</span></li>
                    <li><span>Engineering Systems & Control</span></li>
                    <li><span>Design Build (DB)</span></li>
                    <li><span>Design-Bid-Build (DBB)</span></li>
                    <li><span>Continuous Commisioning® (CC®)</span></li>
                    <li><span>Energy Savings Performance 
                    Contracts (ESPC)</span></li>
                    <li><span>Job Order Contracts (JOC)</span></li>
                    <li><span>Construction Managment (CM)</span></li>
                    <li><span>General Contracting (GC)</span></li>
                    <li><span>Building Information Modeling (BIM)</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='mission-vission'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='details'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/mission-img.png`} />
                  </div>
                  <div className='text'>
                    <h4>Our Mission</h4>
                    <div className="scroll">
                      <p>Our Mission at METCO Engineering Inc. is to provide our clients with state-of-the-art, robust, and sustainable MEP (Mechanical, Electrical, and Plumbing) engineering and systems control services.</p>
                    </div>
                  </div>
                  </div>
              </div>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='details'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/vission-img.png`} />
                  </div>
                  <div className='text'>
                    <h4>Our vision</h4>
                    <div className="scroll">
                      <p>Our Vision is to lead the industry in offering economical, effective energy solutions that provide remarkable outcomes. We are committed to using our cutting-edge technical techniques and unwavering dedication to quality</p>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-metco">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="text">
                  <h2>ABOUT METCO - AN ENERGY SERVICES<br />AND SUSTAINABILITY COMPANY</h2>
                  <p>“METCO is an MEP & Systems Controls Engineering, Design, Construction, and Consultation firm, established in 2004. The company was chartered in Texas, with an aim to provide bonded energy saving guarantees, substantial value, and turn-key energy solutions through enhanced developmental and structural techniques.</p>
                  <p>We firmly believe, 'Advanced Energy Solutions support Sustainability,' and we are proud to be leading the shift toward a more authentic and viable energy efficient solution to meet the imminent needs of our customers at local, regional, and global levels.”</p>
                  <label>- Barry Bagheri, CEO</label>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12">
                <div className="img"> 

                  <img src={`${process.env.PUBLIC_URL}/about-metco.png`} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="certifications">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="head">
                  <h2>Certifications</h2>
                </div>
                <OwlCarousel className='owl-theme blog-slider' loop margin={0} nav>
                  <div className="item"> 
                    <img src={`${process.env.PUBLIC_URL}/certificates-1.png`} />
                  </div>
                  <div className="item">
                    <img src={`${process.env.PUBLIC_URL}/certificates-2.png`} />
                    
                  </div>
                  <div className="item"> 
                    <img src={`${process.env.PUBLIC_URL}/certificates-3.png`} />
                  </div>
                  <div className="item"> 
                    <img src={`${process.env.PUBLIC_URL}/certificates-3.png`} />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        <section className="partners">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="text">
                <h2>Affiliations &<br />Partnerships</h2>
                <div className="scroll">
                  <ul>
                    <li>United States Green Building Council (USGBC)</li>
                    <li>American Society of Heating, Refrigerating and  Air-Conditioning Engineers (ASHRAE)</li>
                    <li>Energy Systems Laboratory | Texas A&M</li>
                    <li>Combined Heat & Power (CHP) | US Environmental Protection Agency (EPA)</li>
                    <li>Industrial Ambassador Network | Texas PACE Authority</li>
                    <li>Texas Energy Managers Association (TEMA)</li>
                    <li>United States Green Building Council (USGBC)</li>
                    <li>American Society of Heating, Refrigerating and  Air-Conditioning Engineers (ASHRAE)</li>
                    <li>Energy Systems Laboratory | Texas A&M</li>
                    <li>Combined Heat & Power (CHP) | US Environmental Protection Agency (EPA)</li>
                    <li>Industrial Ambassador Network | Texas PACE Authority</li>
                    <li>Texas Energy Managers Association (TEMA)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="hor-scroll">
                <OwlCarousel className='owl-theme blog-slider clint-slider' loop margin={10} items={2}>
                  <div className='item'>
                    <div className="clint"> 
                      <img src={`${process.env.PUBLIC_URL}/clint-1.png`} />
                    </div>
                    <div className="clint"> 
                      <img src={`${process.env.PUBLIC_URL}/clint-2.png`} />
                    </div>
                  </div>
                  <div className='item'>
                    <div className="clint">
                    <img src={`${process.env.PUBLIC_URL}/clint-3.png`} /> 
                    </div>
                    <div className="clint"> 
                      
                    <img src={`${process.env.PUBLIC_URL}/clint-4.png`} />
                    </div>
                  </div>
                  <div className='item'>
                    <div className="clint">
                      <img src={`${process.env.PUBLIC_URL}/clint-3.png`} /> 
                    </div>
                    <div className="clint">
                       <img src={`${process.env.PUBLIC_URL}/clint-4.png`} />  
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        <section className='inner-projects'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                
              </div>
            </div>
          </div>
        </section>
        <section className='metco-engenering'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='head'>
                <h2><span>METCO</span> Engineering<br /><span>Projects</span></h2>
                <p>Here is a glimpse of our finished projects spread over a wide variety of multi-disciplinary fields. Our meticulous approach from execution to completion has earned us returning clients, company growth, and strong reputation in the energy sector.</p>
              </div>
              <div className='two-col'>
                <div className='small me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-1.png`} />  
                  </div>
                  <div className='text'>
                    <h4>Engineering Services for Bachman<br />Recreation Centre</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
                <div className='large me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-2.png`} />  
                  </div>
                  <div className='text'>
                    <h4>South Central Police station<br />HVAC Upgrades</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
              </div>
              <div className='btm-two-col'>
                <div className='large me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-3.png`} /> 
                  </div>
                  <div className='text'>
                    <h4>SHoston Airport System (HAS)<br />Energy Audit - IAH</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
                <div className='small me_detail'>
                  <div className='img'> 
                    <img src={`${process.env.PUBLIC_URL}/me-4.png`} /> 
                  </div>
                  <div className='text'>
                    <h4>City of Dallas Park and<br />Recereation ESPC</h4>
                    <p>Upgrade to a Sustainable Lifestyle</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>
        <section className="history">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className='head'>
                  <h2>our history</h2>
                  <p>Here is a glimpse of our finished projects spread over a wide variety of multi-disciplinary fields. Our meticulous approach from execution to completion has earned us returning clients.</p>
                </div>
                <Tabs>
                  <TabList>
                    <Tab>2004-09</Tab>
                    <Tab>2010-12</Tab>
                    <Tab>2014-2015</Tab>
                    <Tab>2016-2020</Tab>
                    <Tab>2021-2023</Tab>
                    <Tab>2024</Tab>
                  </TabList>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='text'>
                          <h3>2004-09</h3>
                          <label>Early Years</label>
                          <ul>
                            <li>Founded in 2004 with a commitment to energy efficiency and sustainability.
                            </li>
                            <li>Introduced services like, MEP design, energy management and savings programs</li>
                            <li>Addressed broader client needs, including ESPC and project management</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='img'>
                          <img src='tab-img-1.png' />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='text'>
                          <h3>2010-12</h3>
                          <label>Early Years</label>
                          <ul>
                            <li>Founded in 2004 with a commitment to energy efficiency and sustainability.
                            </li>
                            <li>Introduced services like, MEP design, energy management and savings programs</li>
                            <li>Addressed broader client needs, including ESPC and project management</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='img'> 
                          <img src={`${process.env.PUBLIC_URL}/tab-img-1.png`} /> 
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='text'>
                          <h3>2014-15</h3>
                          <label>Early Years</label>
                          <ul>
                            <li>Founded in 2004 with a commitment to energy efficiency and sustainability.
                            </li>
                            <li>Introduced services like, MEP design, energy management and savings programs</li>
                            <li>Addressed broader client needs, including ESPC and project management</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='img'>
                          
                          <img src={`${process.env.PUBLIC_URL}/tab-img-1.png`} /> 
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='text'>
                          <h3>2016-20</h3>
                          <label>Early Years</label>
                          <ul>
                            <li>Founded in 2004 with a commitment to energy efficiency and sustainability.
                            </li>
                            <li>Introduced services like, MEP design, energy management and savings programs</li>
                            <li>Addressed broader client needs, including ESPC and project management</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='img'> 
                          <img src={`${process.env.PUBLIC_URL}/tab-img-1.png`} /> 
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='text'>
                          <h3>2021-23</h3>
                          <label>Early Years</label>
                          <ul>
                            <li>Founded in 2004 with a commitment to energy efficiency and sustainability.
                            </li>
                            <li>Introduced services like, MEP design, energy management and savings programs</li>
                            <li>Addressed broader client needs, including ESPC and project management</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='img'> 
                          <img src={`${process.env.PUBLIC_URL}/tab-img-1.png`} /> 
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className='row'>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='text'>
                          <h3>2024</h3>
                          <label>Early Years</label>
                          <ul>
                            <li>Founded in 2004 with a commitment to energy efficiency and sustainability.
                            </li>
                            <li>Introduced services like, MEP design, energy management and savings programs</li>
                            <li>Addressed broader client needs, including ESPC and project management</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='img'> 
                          <img src={`${process.env.PUBLIC_URL}/tab-img-1.png`} /> 
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
        <section className='ceo-message'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='img'> 
                  <img src={`${process.env.PUBLIC_URL}/ceo-img.png`} /> 
                </div>
              </div>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='text'>
                  <h2>Message from CEO</h2>
                  <label>BARRY BAGHERI<br />
                  CEO, P.E, CSE, ELE</label>
                  <div className='scroll'>
                    <p>METCO Engineering Inc. is an MEP & Systems Controls Engineering, Design, Construction, and Consultation firm, established in 2004. The company was chartered in Texas, with an aim to provide bonded energy saving guarantees, substantial value, and turn-key energy solutions through enhanced developmental and structural techniques.</p>
                    <p>We firmly believe, 'Advanced Energy Solutions support Sustainability,' and we are proud to be leading the shift toward a more authentic and viable energy efficient solution to meet the imminent needs of our customers at local, regional, and global levels.</p>
                    <p>We firmly believe, 'Advanced Energy Solutions support Sustainability,' and we are proud to be leading the shift toward a more authentic and viable energy efficient solution to meet the imminent needs of our customers at local, regional, and global levels.</p>
                  </div>
                  <div className='circle'>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='our-team'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='head'>
                  <h2>our team</h2>
                  <p>Here is a glimpse of our finished projects spread over a wide variety of multi-disciplinary fields. Our meticulous approach from execution to completion has earned us returning clients, company growth, and strong reputation in the energy sector.</p>
                </div>
                <OwlCarousel className='owl-theme team-slider' nav loop margin={0} items={3} stagePadding={100}>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                        <img src={`${process.env.PUBLIC_URL}/team-1.png`} /> 
                      </div>
                      <div className='text'>
                        <h4>Jim Saldivar</h4>
                        <p>Controls Manager</p>  
                      </div>
                    </div>
                  </div>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                        <img src={`${process.env.PUBLIC_URL}/team-2.png`} />
                      </div>
                      <div className='text'>
                        <h4>Torsten Vilkner</h4>
                        <p>Head of Production</p>  
                      </div>
                    </div>
                  </div>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                        <img src={`${process.env.PUBLIC_URL}/team-3.png`} />
                      </div>
                      <div className='text'>
                        <h4>Claudia Fickler</h4>
                        <p>Head of Engineering</p>  
                      </div>
                    </div>
                  </div>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                        
                        <img src={`${process.env.PUBLIC_URL}/team-4.png`} />
                      </div>
                      <div className='text'>
                        <h4>Sabrina Munier-Baerns</h4>
                        <p>Head of BD and product transfer</p>  
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        <section className='rnd'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='head-text'>
                  <h2>Research and Development</h2>
                  <p>We are revolutionizing energy savings with groundbreaking upgrades and installations. Our cutting-edge solar PV systems tap into renewable energy, slashing the need for fossil fuels. We're setting new standards with innovative LED lighting upgrades that drastically reduce energy consumption. Our state-of-the-art HVAC installations optimize efficiency and ensure significant energy savings. Additionally, our exploration into resilient energy microgrids guarantees sustainable and reliable power solutions. Through these pioneering efforts, METCO is lighting the way to a greener, more sustainable future.</p>
                  <p>We use a variety of innovative technologies to boost energy efficiency and sustainability:</p>
                </div>
              </div>
              <div className='col-md-6 col-sm-6 col-xs-12'>
                <div className='img'> 
                        
                  <img src={`${process.env.PUBLIC_URL}/rnd-img.png`} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <OwlCarousel className='owl-theme blog-slider' loop margin={0} nav>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                      <img src={`${process.env.PUBLIC_URL}/rnd-1.png`} />
                      </div>
                      <div className='text'>
                        <h4>Building Information<br />Modeling (BIM)</h4>
                        <p>BIM gives a detailed digital view of a building's design, making it easier for architects, engineers, and builders to work together. This leads to smoother construction, lower costs, and better results.</p>  
                      </div>
                    </div>
                  </div>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                        <img src={`${process.env.PUBLIC_URL}/rnd-2.png`} />
                      </div>
                      <div className='text'>
                        <h4>Fuel Cells</h4>
                        <p>BIM gives a detailed digital view of a building's design, making it easier for architects, engineers, and builders to work together. This leads to smoother construction, lower costs, and better results.</p>  
                      </div>
                    </div>
                  </div>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                        <img src={`${process.env.PUBLIC_URL}/rnd-3.png`} />
                      </div>
                      <div className='text'>
                        <h4>Microsoft Power BI</h4>
                        <p>BIM gives a detailed digital view of a building's design, making it easier for architects, engineers, and builders to work together. This leads to smoother construction, lower costs, and better results.</p>  
                      </div>
                    </div>
                  </div>
                  <div class='item'>
                    <div className='details'>
                      <div className='img'> 
                        
                        <img src={`${process.env.PUBLIC_URL}/rnd-1.png`} />
                      </div>
                      <div className='text'>
                        <h4>Building Information<br />Modeling (BIM)</h4>
                        <p>BIM gives a detailed digital view of a building's design, making it easier for architects, engineers, and builders to work together. This leads to smoother construction, lower costs, and better results.</p>  
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        
        
<BlogListCom />


        </>
    )
}

export default WhoWeAre