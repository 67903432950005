// src/utils/apiService.js
const apiService = async (url, method = 'GET', body = null) => {

   
  //const path_base_path = 'https://stagingbh8.beforegoinglive.com/metcoengineering/api/';
  const path_base_path = 'https://apicrm.metcoengineering.com/api/';
  const headers = {
    'Content-Type': 'application/json',
    // Add additional headers if required, e.g., Authorization
  };
 
  const options = {
    method,
    headers,
  };

  if (body) {
    if (body instanceof FormData) {
      // Let the browser set the Content-Type for FormData
      options.body = body;
    } else {
      // Assume it's JSON and set the Content-Type header
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(body);
    }
  }

  try {
      console.log(path_base_path + url); 
    const response = await fetch(path_base_path + url, options);
    console.log("appi hitt 3"); 
    console.log(response); 
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    //return data.data;
    return data;
  } catch (error) {
      // console.error('API Error:', error);
    throw error;
  }
};

export default apiService;
